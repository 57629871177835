/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify, successMessage } from "../../../utils/shared";


const API_URL = process.env.REACT_APP_API_URL;
const CUSTOM_REPORTS = `${API_URL}/adminWeb/customReports`;

export const getCustomReport = createAsyncThunk(
  "getCustomReport",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, startDate, endDate} = values
      const { data } = await axios.get(`${CUSTOM_REPORTS}?startDate=${startDate}&endDate=${endDate}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


