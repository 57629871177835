import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCustomReport } from "./_financialReportActions";

interface FaqState {
    customReports: any;
    loading: boolean;
    selectedFaq: any | null;
    settingSelectedTab: string;
}

const initialState: FaqState = {
    customReports: {},
    loading: false,
    selectedFaq: null,
    settingSelectedTab: "financialReport",
};

export const financialReport = createSlice({
    name: "financialReport",
    initialState,
    reducers: {
        setSelectedFaq: (state, { payload }) => {
            state.selectedFaq = payload;
        },
        setSelectedReportTab: (state, { payload }: PayloadAction<string>) => {
            state.settingSelectedTab = payload;
        },
    },

    extraReducers(builder) {
        builder
            .addCase(getCustomReport.pending, (state) => { state.loading = true; })
            .addCase(getCustomReport.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.customReports = payload.data || {};
            })
            .addCase(getCustomReport.rejected, (state) => { state.loading = false; });
    },
});

export const {
    setSelectedFaq,
    setSelectedReportTab
} = financialReport.actions;

export default financialReport.reducer;
