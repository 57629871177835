// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import { StatusCell } from './StatusCell'
import moment from 'moment'

const subscribedAccountsColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Employee Email' className='min-w-50px' />
    ),
    id: 'employeeEmail',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.employeeDetails?.email} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='First Name' className='min-w-50px' />
    ),
    id: 'firstName',
    Cell: ({ ...props }) => {
      const firstName = props.data[props.row.index]?.employeeDetails?.firstName || "";
      const capitalize = (name: string) =>
        name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

      const formattedName = `${capitalize(firstName)}`;
      return <TitleCell userObj={formattedName} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Last Name' className='min-w-50px' />
    ),
    id: 'lastName',
    Cell: ({ ...props }) => {
      const lastName = props.data[props.row.index]?.employeeDetails?.lastName || "";
      const capitalize = (name: string) =>
        name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

      const formattedName = `${capitalize(lastName)}`;
      return <TitleCell userObj={formattedName} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Phone Number' className='min-w-50px' />
    ),
    id: 'phoneNumber',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.employeeDetails?.phone} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Date of Birth' className='min-w-50px' />
    ),
    id: 'dob',
    Cell: ({ ...props }) => <TitleCell userObj={moment(props.data[props.row.index]?.employeeDetails?.dob).format('DD/MM/YYYY')} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Subscription Status' className='min-w-50px' />
    ),
    id: 'subscriptionStatus',
    Cell: ({ ...props }) => {
      const status = props.data[props?.row?.index]?.status || '';
      const formattedStatus = status.toLowerCase() === 'inactive' ? 'Deactive' : status.charAt(0).toUpperCase() + status.slice(1);

      return <TitleCell userObj={formattedStatus} from="subscription" />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Subscription Package Name' className='min-w-50px' />
    ),
    id: 'effectivePackageName',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.name} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Package Start Date' className='min-w-50px' />
    ),
    id: 'packageStartDate',
    Cell: ({ ...props }) => <TitleCell userObj={moment(props.data[props.row.index].date).format('DD/MM/YYYY')} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Package End Date' className='min-w-50px' />
    ),
    id: 'packageEndDate',
    Cell: ({ ...props }) => <TitleCell userObj={moment(props.data[props.row.index].packageEndDate).format('DD/MM/YYYY')} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Company Name' className='min-w-50px' />
    ),
    id: 'companyName',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.companyDetails?.companyName} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Company Email Domain' className='min-w-50px' />
    ),
    id: 'emailDomain',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.companyDetails?.emailDomain} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Subscription Package Price' className='min-w-50px' />
    ),
    id: 'price',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.price} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Subscription Payment Status' className='min-w-50px' />,
    id: 'paymentStatus',
    Cell: ({ ...props }) => {
      const paymentStatus = props.data[props.row.index]?.paymentStatus;
      const formattedPaymentStatus = paymentStatus
        ? paymentStatus.charAt(0).toUpperCase() + paymentStatus.slice(1).toLowerCase()
        : "";
      return <TitleCell userObj={formattedPaymentStatus} />;
    },    
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Next payment date' className='min-w-50px' />
    ),
    id: 'nextPaymentDate',
    Cell: ({ ...props }) => <TitleCell userObj={moment(props.data[props.row.index].packageEndDate).format('DD/MM/YYYY')} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Check-Ins' className='min-w-50px' />
    ),
    id: 'checkedInCount',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.checkedInCount} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='No-Shows' className='min-w-50px' />
    ),
    id: 'notAttendedCount',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.notAttendedCount} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Early Cancelled' className='min-w-50px' />
    ),
    id: 'earlyCancelledCount',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.earlyCancelledCount} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Late Cancelled' className='min-w-50px' />
    ),
    id: 'lateCancelledCount',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.lateCancelledCount} />,
  },




  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Package Name' className='min-w-50px' />
  //   ),
  //   id: 'packageName',
  //   Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.packageName} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Company Name' className='min-w-50px' />
  //   ),
  //   id: 'companyName',
  //   Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.companyName} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Employee Name' className='min-w-50px' />
  //   ),
  //   id: 'employeeName',
  //   Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.employeeName} />,
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Subscribed Date' className='min-w-50px' />,
  //   id: 'subscribedDate',
  //   Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.subscribedDate} />,
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-50px' />,
  //   id: 'status',
  //   Cell: ({ ...props }) => {
  //     const status = props.data[props?.row?.index]?.status || '';
  //     const formattedStatus = status.toLowerCase() === 'inactive' ? 'Deactive' : status.charAt(0).toUpperCase() + status.slice(1);

  //     return <TitleCell userObj={formattedStatus} from="subscription" />;
  //   },
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Price' className='min-w-50px' />,
  //   id: 'price',
  //   Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.price} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
  //   ),
  //   id: 'action',
  //   Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  // },
]

export { subscribedAccountsColumns }
