import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setFiles,
  setFormDetails,
  setSubscriptionDetails,
} from "../../../../redux/features/shared/sharedSlice";

function SubscriptionDetailsModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);

  const closeModal = () => {
    dispatch(setSubscriptionDetails(false));
    dispatch(setFormDetails({}));
    dispatch(setFiles([]));
  };

  return (
    <>
      <Modal
        className="wrapmodal"
        backdrop="static"
        size="lg"
        show={sharedActions.subscriptionShowDetailsModal}
        onHide={closeModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapmodalInner">
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="half50">
                  <p>
                    <strong>Company:</strong>{" "}
                    {sharedActions.formDetails?.company?.companyName || "N/A"}
                  </p>
                  <p>
                    <strong>Package:</strong>{" "}
                    {sharedActions.formDetails?.package?.name || "N/A"}
                  </p>
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {new Date(
                      sharedActions.formDetails?.startDate
                    ).toLocaleDateString() || "N/A"}
                  </p>
                  <p>
                    <strong>End Date:</strong>{" "}
                    {new Date(
                      sharedActions.formDetails?.endDate
                    ).toLocaleDateString() || "N/A"}
                  </p>
                  <p>
                    <strong>Price:</strong>{" "}
                    {sharedActions.formDetails?.price || "N/A"}
                  </p>
                  <p>
                    <strong>Maximum number of subscribers:</strong>{" "}
                    {sharedActions.formDetails?.max_subscribers || "N/A"}
                  </p>
                  <p>
                    <strong>Description:</strong>{" "}
                    {sharedActions.formDetails?.description || "N/A"}
                  </p>

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { SubscriptionDetailsModal };
