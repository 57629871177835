/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyModalStatus,
  setFiles,
  setFormDetails,
} from "../../../../redux/features/shared/sharedSlice";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { Form } from "react-bootstrap";
import FieldInputText from "../common/InputFeilds/InputTextField";
import * as Yup from "yup";
import { REQUIRED } from "../../../../utils/const";
import {
  addCompany,
  updateCompany,
} from "../../../../redux/features/company/_companyAction";

function CompanyModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);

  const companyFormValidation = Yup.object().shape({
    companyName: Yup.string().required(REQUIRED),
    emailDomain: Yup.string()
      .matches(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email domain format")
      .min(3, "Email domain must be at least 3 characters")
      .max(50, "Email domain must not exceed 50 characters")
      .required(REQUIRED),
    additionalEmailDomains: Yup.array()
      .of(
        Yup.string()
          .matches(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email domain format")
          .min(3, "Email domain must be at least 3 characters")
          .max(50, "Email domain must not exceed 50 characters")
      )
      .nullable(),
    description: Yup.string().required(REQUIRED),
    phone: Yup.string().required(REQUIRED),
    tax: Yup.string().required(REQUIRED),
    numberOfEmployees: Yup.number().required(REQUIRED),
    status: Yup.string(),
  });

  const formInitialValues = {
    companyName: sharedActions.formDetails.companyName || "",
    emailDomain: sharedActions.formDetails.emailDomain || "",
    additionalEmailDomains: sharedActions.formDetails.additionalEmailDomains || [],
    description: sharedActions.formDetails.description || "",
    phone: sharedActions.formDetails.phone || "",
    tax: sharedActions.formDetails.tax || "",
    status: sharedActions.formDetails.status || "",
    numberOfEmployees: sharedActions.formDetails.numberOfEmployees || "",
  };

  const eventFormik = useFormik({
    initialValues: formInitialValues,
    validationSchema: companyFormValidation,
    onSubmit: (values, { resetForm }) => {

      // Filter out empty values from additionalEmailDomains
      const cleanedValues = {
        ...values,
        additionalEmailDomains: values.additionalEmailDomains.filter((domain: string) => domain.trim() !== ""),
      };

      if (sharedActions?.formDetails?._id) {
        dispatch(updateCompany({ ...cleanedValues, id: sharedActions.formDetails?._id }));
      } else {
        dispatch(addCompany({ ...cleanedValues }));
      }

      dispatch(setFormDetails({}));
      setTimeout(() => {
        dispatch(setCompanyModalStatus(false));
        resetForm();
      }, 500);
    },
    enableReinitialize: true,
  });

  const closeModal = () => {
    dispatch(setCompanyModalStatus(false));
    dispatch(setFormDetails({}));
    dispatch(setFiles([]));
  };

  return (
    <Modal backdrop="static" size="lg" show={sharedActions.companyModal} onHide={closeModal} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{sharedActions.formDetails._id ? "Update" : "Add"} Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={eventFormik}>
          <Form onSubmit={eventFormik.handleSubmit} method="POST">
            <div className="row">
              <div className="col-sm-6 col-md-6 mb-6">
                <Form.Group>
                  <Field
                    as="textarea"
                    name="companyName"
                    label="Company Name"
                    component={FieldInputText}
                    maxLength={250}
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-6 mb-6">
                <Form.Group>
                  <Field
                    name="emailDomain"
                    type="text"
                    label="Email Domain"
                    component={FieldInputText}
                    maxLength={50}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 mb-6">
                <Form.Group>
                  <Field
                    as="textarea"
                    name="description"
                    label="Company Description"
                    component={FieldInputText}
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-6 mb-6">
                <Form.Group>
                  <Field name="phone" type="text" label="Contact" component={FieldInputText} />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 mb-6">
                <Form.Group>
                  <Field name="tax" type="text" label="Tax" component={FieldInputText} />
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-6 mb-6">
                <Form.Group>
                  <Field
                    name="numberOfEmployees"
                    type="number"
                    min={0}
                    label="Number of employees"
                    component={FieldInputText}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 mb-6">
                <Form.Group>
                  <label>Additional Email Domains</label>
                  <FieldArray name="additionalEmailDomains">
                    {({ push, remove, form }) => (
                      <div>
                        {form.values.additionalEmailDomains.map((_, index) => {
                          const fieldName = `additionalEmailDomains[${index}]`;

                          return (
                            <div key={index} className="mb-2">
                              <div className="d-flex align-items-center">
                                <Field
                                  name={fieldName}
                                  type="text"
                                  component={FieldInputText}
                                  placeholder="Enter additional email domain"
                                  maxLength={50}
                                />
                                <Button variant="danger" type="button" onClick={() => remove(index)} className="ms-2">
                                  X
                                </Button>
                              </div>
                            </div>
                          );
                        })}

                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => {
                            const lastIndex = form.values.additionalEmailDomains.length - 1;
                            if (
                              form.values.additionalEmailDomains.length === 0 ||
                              (form.values.additionalEmailDomains[lastIndex] && !form.errors.additionalEmailDomains?.[lastIndex])
                            ) {
                              push("");
                            }
                          }}
                        >
                          Add Another Domain
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Form.Group>
              </div>
            </div>

            <Modal.Footer>
              <Button type="button" variant="secondary" className="primaryBtn" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" className="primaryBtn active">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

export { CompanyModal };
