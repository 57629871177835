import { CSVLink } from "react-csv";
import { useState } from "react";
import React from "react";
import { format } from "date-fns"; // For date formatting
import { getBookingsCsvData, getEmployeeCsvData, getPartnerAnalyticsCsvData } from "../../../../../../../utils/csvData";
import { useSelector } from "react-redux";

const DownloadCSVButton = ({ startDate, endDate }: any) => {
    const sharedActions = useSelector((state: any) => state.sharedActions);
    const [csvData, setCsvData] = useState<any>([]);
    const [downloadTriggered, setDownloadTriggered] = useState(false);
    const csvLinkRef = React.useRef<any>(null);

    let monthArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];


    const generateCSVData = async () => {
        // Assuming startDate and endDate are passed as props to the component
        const formattedStart = startDate ? format(startDate, "yyyy-MM-dd") : null;
        const formattedEnd = endDate ? format(endDate, "yyyy-MM-dd") : null;

        if (sharedActions.id === "SubscribedAccounts") {
            const result: any = await getEmployeeCsvData({ page: 1, limit: 10, download: "true", startDate: formattedStart, endDate: formattedEnd });

            if (result?.data) {
                // Map data to desired structure
                const formattedData = result.data.map((item: any) => ({
                    "Employee Email": item.employeeDetails?.email || "N/A",
                    "First Name": item.employeeDetails?.firstName || "N/A",
                    "Last Name": item.employeeDetails?.lastName || "N/A",
                    "Phone Number": item.employeeDetails?.phone || "N/A",
                    "Date of Birth": item.employeeDetails?.dob || "N/A",
                    "Subscription Status": item.status || "N/A",
                    "Subscription Package Name": item.effectivePackageName || "N/A",
                    "Package Start Date": item.date || "N/A",
                    "Package End Date": item.packageEndDate || "N/A",
                    "Company Name": item.companyDetails?.companyName || "N/A",
                    "Company Email Domain": item.companyDetails?.emailDomain || "N/A",
                    "Subscription Package Price": item.price || "N/A",
                    "Subscription Payment Status": item.paymentStatus || "N/A",
                    "Check-Ins": item.checkedInCount || "0",
                    "No-Shows": item.notAttendedCount || "0",
                    "Early Cancelled": item.earlyCancelledCount || "0",
                    "Late Cancelled": item.lateCancelledCount || "0",
                }));

                const headers = Object.keys(formattedData[0]);

                // Prepare data rows
                const dataRows = formattedData.map(item => {
                    return headers.map(header => item[header]);
                });
                const csvContent = headers.map(header => header).join(',') + '\n' + dataRows.map(row => row.join(',')).join('\n');

                setCsvData(csvContent);
                setDownloadTriggered(true);

                // Trigger download
                if (csvLinkRef.current) {
                    csvLinkRef.current.link.click();
                }
            }
        }

        if (sharedActions.id === "PartnerAnalytics") {
            const result: any = await getPartnerAnalyticsCsvData({ page: 1, limit: 10, download: "true", startDate: formattedStart, endDate: formattedEnd });

            if (result?.data) {
                const formattedData = result.data.map((item: any) => {
                    const checkinType = item?.checkinType?.toLowerCase() || "";
                    const checkinRate = parseFloat(item?.checkinRate) || 0;
                    const totalCheckins = parseInt(item?.totalCheckins) || 0;
                    const totalNoShow = parseInt(item?.totalNoShow) || 0;
                    const lateCancelledByUser = parseInt(item?.lateCancelledByUser) || 0;

                    let totalRevenue = 0;

                    if (checkinType === "class") {
                        totalRevenue = (totalCheckins + totalNoShow + lateCancelledByUser) * checkinRate;
                    } else {
                        totalRevenue = checkinRate;
                    }

                    const cleanLocation = item?.location?.replace(/,/g, "") || "N/A";

                    return {
                        "Month": monthArray[parseInt(item?.month) - 1] || "N/A",
                        "Location": cleanLocation,
                        "Partner Email": item?.partnerEmail || "N/A",
                        "Partner Name": item?.partnerName || "N/A",
                        "Total Bookings": item?.totalBookings || "N/A",
                        // "Total Cancelled": item?.totalCancelled || "N/A",
                        "Total No-Shows": item?.totalNoShow || "N/A",
                        "Total Check-ins": item?.totalCheckins || "N/A",
                        "Total Early Cancelled": item?.totalEarlyCancelled || "N/A",
                        "Total Late Cancelled": item?.lateCancelledByUser || "N/A",
                        "Check-in Type": item?.checkinType || "N/A",
                        "Check-in Rate": item?.checkinRate || "N/A",
                        "Total Revenue": totalRevenue || "N/A",
                    };
                });

                const headers = Object.keys(formattedData[0]);

                // Prepare data rows
                const dataRows = formattedData.map(item => {
                    return headers.map(header => item[header]);
                });
                const csvContent = headers.map(header => header).join(',') + '\n' + dataRows.map(row => row.join(',')).join('\n');

                setCsvData(csvContent);
                setDownloadTriggered(true);

                // Trigger download
                if (csvLinkRef.current) {
                    csvLinkRef.current.link.click();
                }
            }
        }

        if (sharedActions.id === "Bookings") {
            const result: any = await getBookingsCsvData({ page: 1, limit: 10, download: "true", startDate: formattedStart, endDate: formattedEnd });

            if (result?.data) {
                const formattedData = result.data.map((item: any) => {

                    const cleanLocation = item?.location?.replace(/,/g, "") || "N/A";

                    // Format the date to display in "28/01/2025 : 6 PM" format
                    const bookingTime = item?.bookingTime;
                    const date = new Date(bookingTime);
                    const formattedDate = bookingTime
                        ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} : ${date.getHours() % 12 || 12} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
                        : '';

                    // Format employee name 
                    const firstName = item?.employeeFirstName || "";
                    const lastName = item?.employeeLastName || "";
                    const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);
                    const employeeName = `${capitalize(firstName)} ${capitalize(lastName)}`


                    return {
                        "Month": monthArray[parseInt(item?.month) - 1] || "N/A",
                        "Booking Time": formattedDate,
                        "Employee Email": item?.employeeEmail || "N/A",
                        "Employee Name": employeeName,
                        "Company Email Domain": item?.companyEmailDomain || "N/A",
                        "Company Name": item?.companyName || "N/A",
                        "Subscription Package": item?.subscriptionPackage || "N/A",
                        "Branch Location": cleanLocation,
                        "Partner Email": item?.partnerEmail || "N/A",
                        "Partner Name": item?.partnerName || "N/A",
                        "Class Date": item?.classDate ? new Date(item?.classDate).toLocaleDateString() : "N/A",
                        "Class Start Time": item?.startTime || "N/A",
                        "Class End Time": item?.endTime || "N/A",
                        "Class Name": item?.className || "N/A",
                        "Booking Status": (item?.status.toLowerCase() === "cancelled") ? "early cancelled" : item?.status,
                    };
                });

                const headers = Object.keys(formattedData[0]);

                // Prepare data rows
                const dataRows = formattedData.map(item => {
                    return headers.map(header => item[header]);
                });
                const csvContent = headers.map(header => header).join(',') + '\n' + dataRows.map(row => row.join(',')).join('\n');

                setCsvData(csvContent);
                setDownloadTriggered(true);

                // Trigger download
                if (csvLinkRef.current) {
                    csvLinkRef.current.link.click();
                }
            }
        }


    };


    return (
        <>
            {(sharedActions.id === "PartnerAnalytics" ||
                sharedActions.id === "SubscribedAccounts" ||
                sharedActions.id === "Bookings") && (

                    <div className="csv-download-button">
                        <button onClick={generateCSVData} className="btn btn-primary">
                            Download CSV
                        </button>

                        {downloadTriggered && (
                            <CSVLink
                                data={csvData}
                                filename={"report.csv"}
                                className="d-none"
                                ref={csvLinkRef}
                            >
                                Download
                            </CSVLink>
                        )}
                    </div>

                )
            }
        </>
    );
};

export { DownloadCSVButton };
