/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import {setSort} from "../../../../../../../redux/features/shared/sharedSlice";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";

const SortOrder = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch = useDispatch();
  const [selectedSort, setSelectedSort] = useState("date");
  const [sortArray, setSortArray] = useState<any>([
    { name: "Date, Ascending", value: "date" },
    { name: "Date, Descending", value: "dateDesc" },
  ]);

  useEffect(() => {
    let array: any = [
      { name: "Date, Ascending", value: "date" },
      { name: "Date, Descending", value: "dateDesc" },
    ];
    if (sharedActions.id === "Company") {
      array.push({ name: "Company Name", value: "name" });
    } else if (sharedActions.id === "Subscription" || sharedActions.id === "SubscribedAccounts") {
      array.push({ name: "Price", value: "price" });
    }
    setSortArray(array);
  }, [dispatch, sharedActions.id]);

  return (
    <>
      {(sharedActions.id === "Company" ||
        sharedActions.id === "Partner" ||
        sharedActions.id === "Subscription" ||
        sharedActions.id === "Partner" ||
        sharedActions.id === "SubscribedAccounts" ||
        sharedActions.id === "Employee" ||
        sharedActions.id === "PartnerAnalytics") && (
        <div
          className="d-flex align-items-center position-relative my-1"
          data-kt-user-table-toolbar="base"
        >
          <div className="d-flex align-items-center gap-2">
            <div className="">
              <span className="sort-text">Sort :</span>
            </div>
            <div className="">
              <Form.Group>
                <Select
                  className="custom-select-box sort-select"
                  value={sortArray?.find((g) => g.value === selectedSort)}
                  name="sorting"
                  placeholder="Sort"
                  getOptionLabel={(o: any) => o?.name}
                  getOptionValue={(o: any) => o?.value}
                  onChange={(v) => {
                    setSelectedSort(v?.value);
                    dispatch(setSort(v?.value));
                  }}
                  options={sortArray}
                />
              </Form.Group>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { SortOrder };
