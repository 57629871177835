/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonTable } from "../common/common-list/table/Table";
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../common/common-list/components/pagination/Pagination";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import { SubscriptionModal } from "./SubscriptionModal";
import './style.scss';
import { UserListHeader } from "../common/common-list/components/header/UserListHeader";
import { getServices } from "../../../../redux/features/service/_serviceAction";
import { servicesColumns } from "../common/common-list/table/columns/_serviceColumns";
import { getCompanies } from "../../../../redux/features/company/_companyAction";
import { subscriptionColumns } from "../common/common-list/table/columns/_subscriptionColumns";
import { getSubscriptions } from "../../../../redux/features/subscription/_subscriptionAction";
import { SubscriptionDetailsModal } from "./SubscriptionDetailsModal";
import { GetSubscriptions } from "../../../../redux/features/payment/_paymentAction";

const SubscriptionList = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch: any = useDispatch();
  const data: any = useSelector((state: any) => state.subscriptionList?.data)||[];
  const totalRecord  = useSelector((state: any) => state.subscriptionList?.totalRecord);

  console.log(totalRecord,">>> total Record >>>")

  useEffect(() => {
    dispatch(setId('Subscription'))
    dispatch(getSubscriptions({ page: 1, limit: 10, sortOrder: sharedActions.sort }));
    dispatch(GetSubscriptions({ page: 1, limit: 10 }));
  }, [dispatch, sharedActions.sort]);
 
  const handleClick = (page: number) => {
    dispatch(getSubscriptions({ page: page, limit: 10, sortOrder: sharedActions.sort }));
  };

  return (
    <>
      <KTCard>
        <UserListHeader />
        <CommonTable data={data} columns={subscriptionColumns} />
        {sharedActions.subscriptionStatusModal && <SubscriptionModal />}
        {sharedActions.subscriptionShowDetailsModal && <SubscriptionDetailsModal /> }
        {totalRecord > 10 && <Pagination
          totalRecord={totalRecord}
          handleClick={handleClick}
        />}
      </KTCard>
    </>
  );
};

export { SubscriptionList };
