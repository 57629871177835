/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonTable } from "../common/common-list/table/Table";
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../common/common-list/components/pagination/Pagination";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import './style.scss';
import { UserListHeader } from "../common/common-list/components/header/UserListHeader";
import { SubscribedAccountDetailsModal } from "./subscribedAccountDetailsModal";
import { getSubscribedAccounts } from "../../../../redux/features/subscribedAccounts/_subscribedAccountsAction";
import { subscribedAccountsColumns } from "../common/common-list/table/columns/_subscribedAccountsColumns";
import { format } from "date-fns"; // For date formatting


const SubscribedAccountList = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch: any = useDispatch();
  const data: any = useSelector((state: any) => state.subscriptionAccounts?.data) || [];
  const totalRecord = useSelector((state: any) => state.subscriptionAccounts?.totalRecord);

  // Calculate default date range (past 1 month)
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);
  // Format the dates before dispatching
  const formattedStart = oneMonthAgo ? format(oneMonthAgo, "yyyy-MM-dd") : null;
  const formattedEnd = today ? format(today, "yyyy-MM-dd") : null;

  useEffect(() => {
    dispatch(setId('SubscribedAccounts'));
    dispatch(getSubscribedAccounts({ page: 1, limit: 10, sortOrder: sharedActions.sort, startDate: formattedStart, endDate: formattedEnd }));
  }, [dispatch, sharedActions.sort]);

  const handleClick = (page: number) => {
    dispatch(getSubscribedAccounts({ page: page, limit: 10, sortOrder: sharedActions.sort, startDate: sharedActions.startTime, endDate: sharedActions.endTime }));
  };

  return (
    <>
      <KTCard>
        <UserListHeader />
        <CommonTable data={data} columns={subscribedAccountsColumns} />
        {sharedActions.subscribedAccountDetailsModal && <SubscribedAccountDetailsModal />}
        {totalRecord > 10 && <Pagination
          totalRecord={totalRecord}
          handleClick={handleClick}
        />}
      </KTCard>
    </>
  );
};

export { SubscribedAccountList };
