import { Button, Spinner } from "react-bootstrap"


const SubmitButton = (props) => {
    return (
        <>
            <Button variant="primary" type={props.type} disabled={props.loading}>
                {props.loading ? (
                    <>
                        <Spinner animation="border" size="sm" className="me-2" role="status" aria-hidden="true" />
                        Please Wait
                    </>
                ) : (
                    <>
                        {props.title}
                    </>
                )}
            </Button>
        </>
    )
}


export default SubmitButton