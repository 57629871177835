import React from "react";

const AngleWhiteTopIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_692_1687"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect
          x="20"
          y="20"
          width="20"
          height="20"
          transform="rotate(-180 20 20)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_692_1687)">
        <path
          d="M10.0007 7.52091C10.1118 7.52091 10.2159 7.53828 10.3132 7.573C10.4104 7.60772 10.5007 7.66675 10.584 7.75008L14.4173 11.5834C14.5701 11.7362 14.6465 11.9306 14.6465 12.1667C14.6465 12.4029 14.5701 12.5973 14.4173 12.7501C14.2645 12.9029 14.0701 12.9792 13.834 12.9792C13.5979 12.9792 13.4034 12.9029 13.2507 12.7501L10.0007 9.50008L6.75065 12.7501C6.59787 12.9029 6.40343 12.9792 6.16732 12.9792C5.93121 12.9792 5.73676 12.9029 5.58398 12.7501C5.43121 12.5973 5.35482 12.4029 5.35482 12.1667C5.35482 11.9306 5.43121 11.7362 5.58398 11.5834L9.41732 7.75008C9.50065 7.66675 9.59093 7.60772 9.68815 7.573C9.78537 7.53828 9.88954 7.52091 10.0007 7.52091Z"
          fill="#3c3c3c"
        />
      </g>
    </svg>
  );
};

export default AngleWhiteTopIcon;
