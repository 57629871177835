/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword, verifyPasswordLink } from '../core/_requests'
import { AxiosError } from 'axios'
import { notify } from '../../../../utils/shared'

export function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(true);
  const [params] = useSearchParams();
  const id = params.get("id");
  const otp = params.get("otp");
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");


  useEffect(() => {
    verifyForgotPasswordLink();
  }, []);

  const verifyForgotPasswordLink = async () => {
    try {
      if (!id || !otp) {
        setHasErrors("The link you used is invalid");
        notify("The link you used is invalid", 'error');
        navigate('/auth/forgot-password');
      }
      else {
        try {
          const result: any = await verifyPasswordLink(id, otp);
          setEmail(result?.data?.data?.email)
        } catch (error: any) {
          console.log(error)
          setLoading(false);
          notify(error?.response?.data?.responseMessage, 'error');
          navigate('/auth/forgot-password');
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log("The link you used is invalid");
      }
    }
  }

  const initialValues = {
    password: ""
  }
  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters")
      .matches(/[A-Z]/, "One uppercase is required")
      .matches(/[a-z]/, "One lowercase is required")
      .matches(/[0-9]/, "One number is required")
      .matches(/[@#%!-]/, "One special character is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)

      try {
        const result: any = await resetPassword(values.password, email);
        notify(result?.data?.responseMessage, 'success');
        navigate('/auth/login');
      } catch (error: any) {
        console.log(error)
        setSubmitting(false);
        setLoading(false);
        notify(error?.response?.data?.responseMessage, 'error');
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Set your new password</h1>

        <div className='text-gray-500 fw-semibold fs-6'>
          Create a password with combine of alphabets, numbers and
          symbols (@,#,%, !){" "}
        </div>
      </div>

      {hasErrors && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      <div className="fv-row mb-3">
        <div className="position-relative">
          <input
            placeholder="Enter your new Password"
            type="email"
            value={email}
            disabled={true}
            className="form-control bg-transparent is-valid"
          />
        </div>
      </div>

      <div className="fv-row mb-3">
        <div className="position-relative">
          <input
            placeholder="Enter your new Password"
            type={showPassword ? "password" : "text"}
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <i
            className={
              showPassword
                ? "bi bi-eye-slash-fill eyeIcon"
                : "bi bi-eye-fill eyeIcon"
            }
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          ></i>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>

    </form>
  )
}
