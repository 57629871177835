// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import moment from 'moment'


const notificationColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Title' className='min-w-50px' />
    ),
    id: 'title',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.message?.title} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Description' className='min-w-50px' />,
    id: 'description',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.message?.body} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Time' className='min-w-50px' />,
    id: 'time',
    Cell: ({ ...props }) => <TitleCell userObj={moment(props.data[props.row.index]?.createdAt).format('MMM DD, YYYY: hh:mm A')} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  },
]

export { notificationColumns }
