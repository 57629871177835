/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx'
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  defaultAlerts,
  defaultLogs,
  KTIcon,
  toAbsoluteUrl,
  useIllustrationsPath,
} from '../../../helpers'
import moment from 'moment';

interface HeaderNotificationsMenuProps {
  notifications: any[];
  totalRecord: number;
}

const HeaderNotificationsMenu: FC<HeaderNotificationsMenuProps> = ({
  notifications,
  totalRecord,
}) => (

  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-no-repeat rounded-top'
      style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
        Notifications <span className='fs-8 opacity-75 ps-3'>{totalRecord} reports</span>
      </h3>

    </div>

    <div className='tab-content'>
      <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>

          {notifications?.length > 0 ? (
            notifications.map((notification: any, index: number) => (
              <div key={`notification-${index}`} className='d-flex flex-stack py-4'>

                <div className='d-flex align-items-center justify-content-between'>
                  <div className='mb-0 me-2'>
                    <div className='fs-6 text-gray-800 fw-bolder'>
                      {notification?.message?.title}
                    </div>
                    <div className='text-gray-400 fs-7 mb-2'>{notification?.message?.body}</div>

                    <span className='badge-light fs-8'>
                      {moment(notification?.createdAt).format('MM/DD/YYYY - hh:mm A')}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='text-center'>No notifications available</div>
          )}
        </div>

        {notifications?.length > 0 && (
          <div className='py-3 text-center border-top'>
            <button
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTIcon iconName='arrow-right' className='fs-5' />
            </button>
          </div>
        )}

      </div>

    </div>
  </div>
)

export { HeaderNotificationsMenu }
