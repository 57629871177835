import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";

const FinancialReport = ({ data }: { data: any }) => {

  return (
    <div className="p-3">
      <Row className="mb-3">
        <Col md={4}>
          <Card className="p-3">
            <h5>Total revenue from subscribers</h5>
            <p>{data?.totalEmployeeRevenue || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total payment to fitness partner</h5>
            <p>{data?.totalPartnerPayment || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Fitpond profit</h5>
            <p>{data?.profit || "0"}</p>
          </Card>
        </Col>
      </Row>     
    </div>
  );
};

export default FinancialReport;
