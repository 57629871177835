import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";

const EngagementReport = ({ data }: { data: any }) => {

  return (
    <div className="p-3">
      <Row className="mb-3">
        <Col md={4}>
          <Card className="p-3">
            <h5>Total Employees</h5>
            <p>{data?.totalNumberOfEmployees || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total Subscribers</h5>
            <p>{data?.totalNumberOfSubscribers || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total Cancelled Subscriptions</h5>
            <p>{data?.totalCancelledSubscribers || "0"}</p>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <Card className="p-3">
            <h5>Total Bookings</h5>
            <p>{data?.totalBookings || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total No-Shows</h5>
            <p>{data?.totalNoShows || "0"}</p>
          </Card>
        </Col> <Col md={4}>
          <Card className="p-3">
            <h5>Total Check-ins</h5>
            <p>{data?.totalCheckins || "0"}</p>
          </Card>
        </Col>
        {/* <Col md={4}>
          <Card className="p-3">
            <h5>Total Cancelled Classes</h5>
            <p>{data?.totalCanceled || "0"}</p>
          </Card>
        </Col> */}
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <Card className="p-3">
            <h5>Total Early Cancelled</h5>
            <p>{data?.totalEarlyCancelled || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total Late Cancelled</h5>
            <p>{data?.totalLateCancelled || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total branches</h5>
            <p>{data?.totalNumberOfBranches || "0"}</p>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <Card className="p-3">
            <h5>Total branches with at least 1 class posted</h5>
            <p>{data?.totalLocationsWithClasses || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total Partners</h5>
            <p>{data?.totalPartners || "0"}</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Total partners with at least 1 class posted</h5>
            <p>{data?.totalPartnersWithClasses || "0"}</p>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <Card className="p-3">
            <h5>Churn Rate</h5>
            <p>{data?.churnRate || "0"}%</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Utilization</h5>
            <p>{data?.utilization || "0"}%</p>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-3">
            <h5>Conversion Rate</h5>
            <p>{data?.conversionRate || "0"}%</p>
          </Card>
        </Col>
      </Row>

      <h4 className="mt-4">Top Booked Classes</h4>
      {data?.topBookedClasses?.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Class Name</th>
              <th>Total Bookings</th>
              <th>Total Check-ins</th>
            </tr>
          </thead>
          <tbody>
            {data?.topBookedClasses.map((cls: any, index: number) => (
              <tr key={index}>
                <td>{cls?.className || "N/A"}</td>
                <td>{cls?.totalBookings || "0"}</td>
                <td>{cls?.totalCheckins || "0"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-3">No data available</p>
      )}

      <h4 className="mt-4">Top Companies</h4>
      {data?.topCompanies?.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Subscriber Count</th>
            </tr>
          </thead>
          <tbody>
            {data?.topCompanies.map((company: any, index: number) => (
              <tr key={index}>
                <td>{company?.companyName || "N/A"}</td>
                <td>{company?.subscriberCount || "0"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-3">No data available</p>
      )}

      <h4 className="mt-4">Top Partners</h4>
      {data?.topPartners?.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Partner Name</th>
              <th>Check-in Count</th>
            </tr>
          </thead>
          <tbody>
            {data?.topPartners.map((partner: any, index: number) => (
              <tr key={index}>
                <td>{partner?.partnerName || "N/A"}</td>
                <td>{partner?.checkinCount || "0"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-3">No data available</p>
      )}

    </div>
  );
};

export default EngagementReport;
