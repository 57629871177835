// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import { StatusCell } from './StatusCell'

let monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const partnerAnalyticsColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Month' className='min-w-50px' />
    ),
    id: 'month',
    Cell: ({ ...props }) => <TitleCell userObj={monthArray[parseInt(props.data[props.row.index]?.month) - 1]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Location' className='min-w-50px' />
    ),
    id: 'location',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.location} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Partner Email' className='min-w-50px' />
    ),
    id: 'partnerEmail',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.partnerEmail} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Partner Name' className='min-w-50px' />
    ),
    id: 'partnerName',
    Cell: ({ ...props }) => {
      const partnerName = props.data[props.row.index]?.partnerName || '';
      const formattedPartnerName = partnerName.charAt(0).toUpperCase() + partnerName.slice(1);
      return <TitleCell userObj={formattedPartnerName} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Total Bookings' className='min-w-50px' />
    ),
    id: 'totalBookings',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.totalBookings} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Total Cancelled' className='min-w-50px' />
  //   ),
  //   id: 'totalCancelled',
  //   Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.totalCancelled} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Total No-Shows' className='min-w-50px' />
    ),
    id: 'totalNoShow',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.totalNoShow} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Total Check-ins' className='min-w-50px' />
    ),
    id: 'totalCheckins',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.totalCheckins} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Total Early Cancelled' className='min-w-50px' />
    ),
    id: 'totalEarlyCancelled',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.totalEarlyCancelled} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Total Late Cancelled' className='min-w-50px' />
    ),
    id: 'lateCancelledByUser',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.lateCancelledByUser} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Check-in Type' className='min-w-50px' />
    ),
    id: 'checkinType',
    Cell: ({ ...props }) => {
      const checkinType = (props.data[props.row.index]?.checkinType?.toLowerCase() == 'class') ? "Per Booking" : "Per Month";
      return <TitleCell userObj={checkinType} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Check-in Rate' className='min-w-50px' />
    ),
    id: 'checkinRate',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.checkinRate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Total Revenue' className='min-w-50px' />
    ),
    id: 'totalRevenue',
    Cell: ({ ...props }) => {
      const checkinType = props.data[props?.row?.index]?.checkinType || '';
      const checkinRate = parseFloat(props.data[props?.row?.index]?.checkinRate)
      let calculatedRevenue = 0;

      if(checkinType.toLowerCase() === 'class'){
        calculatedRevenue = (parseInt(props.data[props?.row?.index]?.totalCheckins) + parseInt(props.data[props?.row?.index]?.totalNoShow) + parseInt(props.data[props?.row?.index]?.lateCancelledByUser)) * checkinRate
      }
      else{
        calculatedRevenue = checkinRate
      }

      return <TitleCell userObj={calculatedRevenue} from="subscription" />;
    },
  },
]

export { partnerAnalyticsColumns }
