/* eslint-disable react-hooks/exhaustive-deps */
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormDetails,
  setWellnessTypesModalStatus,
} from "../../../../redux/features/shared/sharedSlice";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "react-bootstrap";
import FieldInputText from "../common/InputFeilds/InputTextField";
import * as Yup from "yup";
import {
  REQUIRED,
} from "../../../../utils/const";
import { useEffect, useState } from "react";
import { LANG } from "../../../constants/language";
import logo from "../../../../_metronic/assets/logo/admin-logo.png";
import { addWellnessType, updateWellnessType } from "../../../../redux/features/wellnessTypes/_wellnessTypesAction";

function WellnessTypesModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);
  const [imageUrl, setImageUrl] = useState<any>();

  const FormValidation = Yup.object().shape({
    name: Yup.string().matches(/^[a-zA-Z\s]+$/, "Name should only contain alphabets and spaces").required(REQUIRED),
    image: Yup.mixed(),
    description: Yup.string(),
  });

  const formIntialValues = {
    description: sharedActions.formDetails.description || "",
    name: sharedActions.formDetails.name || "",
    image: sharedActions?.formDetails?.image || "",
  };

  const wellnessTypesFormik = useFormik({
    initialValues: formIntialValues,
    validationSchema: FormValidation,
    onSubmit: async (values: any, { resetForm }) => {
      values.selectedPage = sharedActions.selectedPage;
      if (sharedActions?.formDetails?._id) values.id = sharedActions.formDetails?._id;
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]: any) => {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (typeof value === "object" && value !== null) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });

      if (sharedActions?.formDetails?._id) {
        await dispatch(
          updateWellnessType({
            formData,
            id: sharedActions.formDetails?._id,
          })
        )
      } else {
        await dispatch(addWellnessType(formData));
      }

      dispatch(setFormDetails({}));
      setTimeout(() => {
        dispatch(setWellnessTypesModalStatus(false));
        resetForm();
      }, 500);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (sharedActions?.formDetails?._id && sharedActions?.formDetails?.image) {
      setImageUrl(`${process.env.REACT_APP_FILE_URL}${sharedActions?.formDetails?.image}`);
    }
  }, [sharedActions?.formDetails?._id, sharedActions?.formDetails?.image]);

  const closeModal = () => {
    dispatch(setWellnessTypesModalStatus(false));
    dispatch(setFormDetails({}));
  };

  const handleChangeProfileImage = async (e: any) => {
    const selectedFile = e.target.files[0];
    const url = URL.createObjectURL(selectedFile);
    wellnessTypesFormik.setFieldValue("image", selectedFile);
    setImageUrl(url);
  };

  return (
    <>
      <Modal
        backdrop="static"
        size="lg"
        show={sharedActions.wellnessTypesModal}
        onHide={closeModal}
        animation={true}
        style={{
          maxWidth: '500px',
          margin: '0 auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!sharedActions.formDetails._id ? "Add" : "Update"} Business Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={wellnessTypesFormik}>
            <Form onSubmit={wellnessTypesFormik.handleSubmit} method="POST">
              {/* <div className="col-sm-12  col-md-6 mb-6 image-upload position-relative">
                <img
                  className="w-50"
                  src={imageUrl ? imageUrl : logo}
                  alt="profile"
                />
                <label
                  title={LANG.CHANGE}
                  htmlFor="file-input"
                  className="position-absolute bottom-0"
                >
                  <i className="bi bi-pencil-square ms-2"></i>
                </label>
                <input
                  onChange={handleChangeProfileImage}
                  id="file-input"
                  type="file"
                />
              </div> */}

              <div className="row">
                <div className="col-sm-12 col-md-12 mb-6">
                  <Form.Group>
                    <Field
                      name="name"
                      validate={FormValidation}
                      type="text"
                      label={LANG.NAME}
                      isRequired={true}
                      component={FieldInputText}
                      placeholder={LANG.NAME}
                      maxLength={30}
                      style={{ textTransform: "capitalize" }}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-12 mb-6">
                  <Form.Group>
                    <Field
                      as={"textarea"}
                      name="description"
                      validate={FormValidation}
                      label={LANG.DESCRIPTION}
                      component={FieldInputText}
                      maxLength={30}
                      style={{ textTransform: "uppercase" }}
                    />
                  </Form.Group>
                </div>
              </div>

              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  className="primaryBtn"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit" className="primaryBtn active">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { WellnessTypesModal };
