import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "./_notificationAction";

const initialState: any = {
  data: [],
  isLoading: true,
  isSuccess: false,
  responseCode: null,
  responseMessage: '',
  totalRecord: 0,
  openNotificationListModal: false, // Modal visibility state
  notificationCount: 0, // Notification count state
  openDeleteTermModal: false,
  selectedNotification: null,
};

export const notificationList = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    resetNotificationCount: (state) => {
      state.notificationCount = 0; // Reset notification count
    },
    incrementNotificationCount: (state) => {
      state.notificationCount += 1; // Increment notification count
    },
    openNotificationModal: (state) => {
      state.openNotificationListModal = true; // Open notification modal
    },
    closeNotificationModal: (state) => {
      state.openNotificationListModal = false; // Close notification modal
    },

    setOpenDeleteNotificationModal: (state) => {
      state.openDeleteNotificationModal = true
    },
    setCloseDeleteNotificationModal: (state) => {
      state.openDeleteNotificationModal = false
    },

    setSelectedNotification: (state, { payload }) => {
      state.selectedNotification = payload;
    }

  },
  extraReducers(builder) {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data || [];
        state.responseMessage = payload.responseMessage;
        state.responseCode = payload.responseCode;
        state.totalRecord = payload.totalRecord || 0;
      })
      .addCase(getNotifications.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});

// Export the actions to dispatch them
export const { resetNotificationCount, incrementNotificationCount, openNotificationModal, closeNotificationModal, setOpenDeleteNotificationModal, setCloseDeleteNotificationModal, setSelectedNotification } = notificationList.actions;

export default notificationList.reducer;
