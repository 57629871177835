import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill CSS
import SubmitButton from "../common/common-list/components/submitButton/submitButton";

const TermAndConditionsForm = ({
    showModal,
    handleClose,
    onSubmit,
    initialValues,
    title,
    loading
}) => {
    
    const [content, setContent] = useState('');
    useEffect(() => {
        setContent(initialValues.content)
    }, [initialValues.content]);

    const validationSchema = Yup.object({
        title: Yup.string()
            .required("Title is required"),
        content: Yup.string()
            .required("Content is required")
    });

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title !== "Create" ? "Update Term" : "Create Term"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        onSubmit({ ...values, content });
                    }}
                >
                    {({ setFieldValue, errors, touched, isSubmitting }: any) => (
                        <FormikForm>
                            <Form.Group controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Field
                                    name="title"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter the Title"
                                />
                                <ErrorMessage name="title" component="div" className="text-danger" />
                            </Form.Group>

                            {/* Content (ReactQuill Editor) */}
                            <Form.Group controlId="content" className="mt-3">
                                <ReactQuill
                                    value={content}
                                    onChange={(value) => {
                                        setContent(value);
                                        setFieldValue("content", value);
                                    }}
                                    theme="snow"

                                />
                                {errors.content && touched.content && (
                                    <Alert variant="danger" className="mt-2">
                                        {errors.content}
                                    </Alert>
                                )}
                            </Form.Group>

                            {/* Footer with Buttons */}
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
   
                                <SubmitButton  type = "submit" loading = {loading} title = 
                                {title !== "Create" ? "Save Changes" : "Create Term"}/>

                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default TermAndConditionsForm;
