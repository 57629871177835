import { useEffect, useState } from "react";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import { getDashboardData } from "../../../../redux/features/dashboard/_dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch: any = useDispatch();
  const data: any = useSelector((state: any) => state.dashboardData?.data) || [];
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setId('Dashboard'))
    dispatch(getDashboardData({}));
  }, [dispatch, sharedActions.dashboardData]);

  const handleRedirect = () => {
    navigate('/subscriptions/list');
  };


  return (
    <div className="mt-5">
      <h1 className="mb-5">Dashboard</h1>

      <div style={{ marginTop: "15px" }}>

        <div className="row">
          {/* Card 1: Users */}
          <div className="col-md-4 mb-3 d-flex justify-content-center">
            <div className="card card-block emp-block w-100 text-white  d-flex align-items-center justify-content-center"
              // style={{ width: "100%", height: "200px" }}
            >
              <div className="card-body text-center">
                <h5 className="card-title">Total Active Employee</h5>
                <p className="card-text fs-2">{data?.totalActiveEmployee}</p>
              </div>
            </div>
          </div>

          {/* Card 2: Total Companies< */}
          <div className="col-md-4 mb-3 d-flex justify-content-center">
            <div
              className="card w-100 card-block company-block text-white  d-flex align-items-center justify-content-center"    
              // style={{ width: "100%", height: "200px" }}
            >
              <div className="card-body text-center">
                <h5 className="card-title">Total Companies</h5>
                <p className="card-text fs-2">{data?.totalCompanies}</p>
              </div>
            </div>
          </div>

          {/* Card 3: Total Partners */}
          <div className="col-md-4 mb-3 d-flex justify-content-center">
            <div
              className="card w-100 card-block partner-block text-white d-flex align-items-center justify-content-center"
              // style={{ width: "100%", height: "200px" }}
            >
              <div className="card-body text-center">
                <h5 className="card-title">Total Partners</h5>
                <p className="card-text fs-2">{data?.totalPartners}</p>
              </div>
            </div>
          </div>

          {/* Card 4: Active Partners */}
          <div className="col-md-6 mb-3 d-flex justify-content-center">
            <div
              className="card w-100 card-block active-block text-white  d-flex align-items-center justify-content-center"
              // style={{ width: "100%", height: "200px" }}
            >
              <div className="card-body text-center">
                <h5 className="card-title">Active Partners</h5>
                <p className="card-text fs-2">{data?.activePartners}</p>
              </div>
            </div>
          </div>

          {/* Card 5: Revenue */}
          <div className="col-md-6 mb-3 d-flex justify-content-center">
            <div
              className="card w-100 card-block payment-block text-white  d-flex align-items-center justify-content-center"
              // style={{ width: "100%", height: "200px", cursor: "pointer" }}
              onClick={handleRedirect}
            >
              <div className="card-body text-center">
                <h5 className="card-title">Subscriptions & Payments</h5>
                <p className="card-text fs-2">{data?.totalSubscriptionPlan}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

