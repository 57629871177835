import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns"; // For date formatting
import { toast } from "react-toastify"; // Toast for error messages
import { useDispatch, useSelector } from "react-redux";
import { setEndTime, setStartTime } from "../../../../../../../redux/features/shared/sharedSlice";

const DateRangeSelector = ({ onDateChange }: any) => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Date | null>(oneMonthAgo);
  const [endDate, setEndDate] = useState<Date | null>(today);

  // Handle changes in date range
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const formattedStart = start ? format(start, "yyyy-MM-dd") : null;
    const formattedEnd = end ? format(end, "yyyy-MM-dd") : null;
    dispatch(setStartTime(formattedStart));
    dispatch(setEndTime(formattedEnd));

    // Validation to ensure both dates are selected
    if (start && !end) {
      toast.error("Please select an end date to complete the range.");
    } else if (!start && end) {
      toast.error("Please select a start date to complete the range.");
    } else if (!start && !end) {
      toast.error("A date range must be selected.");
    } else {
      // Pass the selected dates to the parent component
      onDateChange(start, end);
    }
  };

  return (
    <>
      {(sharedActions.id === "PartnerAnalytics" ||
        sharedActions.id === "SubscribedAccounts" ||
        sharedActions.id === "Bookings" ||
        sharedActions.id === "CustomReport") && (

          <div className="d-flex align-items-center position-relative my-1">
            <div className="d-flex align-items-center gap-2">
              <div className="">
                <span className="sort-text">Filter :</span>
              </div>
              <div className="date-range-picker">
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  isClearable
                  placeholderText="Select date range"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export { DateRangeSelector };
