/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Table, Container, Row, Col, Navbar, Nav, Card, ButtonGroup } from 'react-bootstrap';
import './style.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import TermAndConditions from './termsAndConditionForm';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTerm, DeleteTerm, TermsList, UpdateTerm } from '../../../../redux/features/terms/_termAction';
import { setCloseCreateTermModal, setOpenCreateTermModal, setOpenDeleteTermModal, setSelectedTerm } from '../../../../redux/features/terms/termSlice';
import DeleteConfirmationModal from '../common/common-list/components/deleteConfirmationModel/deleteConfirmationModal';
import TermAndConditionsForm from './termsAndConditionForm';

const TermsAndConditions = () => {
    const dispatch: any = useDispatch();
    const termsActions = useSelector((state: any) => state.termsActions);
    const { terms } = useSelector((state: any) => state.terms);
    const { loading } = useSelector((state: any) => state.createTerm)

    const [title, setTitle]: any = useState('Add');

    useEffect(() => {
        dispatch(TermsList())

        //         "_id": "607c72ef2f53f32bc4b0e16c",
        //         "title": "Terms of Service",
        //         "content": "By accessing and using this service, you agree to the following terms and conditions. You must be at least 18 years old to use this service. You agree not to use the service for illegal activities, and you agree to follow all applicable laws. We reserve the right to modify or discontinue the service at any time without notice. You acknowledge that we are not responsible for any damages caused by the use of this service, including but not limited to loss of data or service interruptions.",
        //         "createdAt": "2021-04-12T14:48:12.000Z",
        //         "updatedAt": "2021-04-12T14:48:12.000Z"
        //     },
        //     {
        //         "_id": "607c730d2f53f32bc4b0e16d",
        //         "title": "Privacy Policy",
        //         "content": "We value your privacy and are committed to protecting your personal data. This policy outlines how we collect, use, and safeguard your information. We collect personal information such as your name, email address, and usage data. This information is used to provide you with better services and communicate with you. We do not sell your personal data to third parties. We take reasonable precautions to secure your data from unauthorized access. However, we cannot guarantee complete security in the event of a data breach.",
        //         "createdAt": "2021-04-12T14:50:12.000Z",
        //         "updatedAt": "2021-04-12T14:50:12.000Z"
        //     },
        //     {
        //         "_id": "607c731d2f53f32bc4b0e16e",
        //         "title": "Cookie Policy",
        //         "content": "This website uses cookies to improve your experience while navigating the site. Cookies are small files that are stored on your device. They help us analyze web traffic, remember your preferences, and enhance the functionality of the website. By using this site, you consent to the use of cookies. If you do not wish to accept cookies, you can disable them in your browser settings. However, doing so may affect the functionality of the site.",
        //         "createdAt": "2021-04-12T14:52:12.000Z",
        //         "updatedAt": "2021-04-12T14:52:12.000Z"
        //     },
        //     {
        //         "_id": "607c732e2f53f32bc4b0e16f",
        //         "title": "Refund Policy",
        //         "content": "If you are not satisfied with our service, you may be eligible for a refund within 30 days of your purchase. To request a refund, please contact our support team with your order details. Refunds will be issued to the original payment method. Refunds are subject to approval and may take up to 10 business days to process. Some services may not be eligible for refunds, depending on the terms of the specific service purchased.",
        //         "createdAt": "2021-04-12T14:54:12.000Z",
        //         "updatedAt": "2021-04-12T14:54:12.000Z"
        //     },
        //     {
        //         "_id": "607c73412f53f32bc4b0e170",
        //         "title": "User Agreement",
        //         "content": "This agreement outlines the terms and conditions of your use of our services. By using the service, you agree to follow the rules set forth in this agreement. You agree to provide accurate and truthful information during the registration process. You agree to keep your account credentials secure and not share them with others. You are responsible for all activities that occur under your account. Violations of this agreement may result in termination of your account and access to our services.",
        //         "createdAt": "2021-04-12T14:56:12.000Z",
        //         "updatedAt": "2021-04-12T14:56:12.000Z"
        //     }
        // ]
        // setTerms(dummyFaqs);  // Set the dummy data into the state
    }, []);


    const handleDelete = () => {
        dispatch(DeleteTerm(termsActions.selectedTerm._id))
    };

    const handleAddOrEdit = (term: any, title: string) => {
        dispatch(setSelectedTerm(term));
        setTitle(title);
        dispatch(setOpenCreateTermModal());

    };

    const handleCloseModal = () => {
        dispatch(setCloseCreateTermModal());
        dispatch(setSelectedTerm(null));
    };


    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        content: Yup.string().required('Content is required'),
    });


    const handleSubmit = (values) => {
        if (termsActions.selectedTerm) {
            delete values._id

            let obj = {
                term: termsActions.selectedTerm,
                values
            }
            dispatch(UpdateTerm(obj))
        }
        else { dispatch(CreateTerm(values)) };
    };

    const removeHtmlTags = (inputString: string): string => {
        let input = inputString.replace(/<[^>]*>/g, '');
        return input.substring(0, 100)
    };


    return (
        <div style={{ backgroundColor: '#f8f9fa' }}>
            <Container className="my-5">
                <Row className="mb-4">
                    <Col className="d-flex justify-content-between">
                        <h1>Terms & Conditions List</h1>
                        {terms?.length !== 0 && <Button
                            variant="primary"
                            onClick={() => {
                                dispatch(setSelectedTerm(null))
                                dispatch(setOpenCreateTermModal())
                                setTitle('Create')
                            }}
                        >
                            Create New Term
                        </Button>}
                    </Col>
                </Row>

                <Row>
                    {terms?.length > 0 ? (
                        terms?.map((term) => (
                            <Col md={4} lg={3} key={term._id} className="mb-4">
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{term.title}</Card.Title>
                                        <Card.Text>
                                            {removeHtmlTags(term.content)}...
                                        </Card.Text>
                                        <div className="d-flex justify-content-end gap-2">
                                            <Button size="sm" onClick={() => handleAddOrEdit(term, 'Update')} >
                                                Update
                                            </Button>
                                            <Button variant="danger" size="sm" onClick={() => {
                                                dispatch(setOpenDeleteTermModal())
                                                dispatch(setSelectedTerm(term))
                                            }}>
                                                Delete
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <div className="text-center my-5">
                            <h5 className="text-muted">
                                No Terms and conditions yet? Let's Create the first one!
                            </h5>

                            <Button
                                variant="primary"
                                onClick={() => {
                                    dispatch(setSelectedTerm(null))
                                    dispatch(setOpenCreateTermModal())
                                    setTitle('Create')
                                }}
                            >
                                Create Terms
                            </Button>
                        </div>
                    )}
                </Row>
            </Container>
            <TermAndConditionsForm
                showModal={termsActions.openCreateTermsModal}
                handleClose={handleCloseModal}
                onSubmit={handleSubmit}
                title={title}
                loading={loading}
                initialValues={termsActions.selectedTerm || { title: '', content: '' }} />

            {termsActions.openDeleteTermModal && <DeleteConfirmationModal
                show={termsActions.openDeleteTermModal}
                handleClose={() => dispatch(setOpenDeleteTermModal())}
                handleDelete={handleDelete}
                item={termsActions.selectedTerm}
            />}
        </div>

    );
};

export default TermsAndConditions;