import axios from 'axios'
import { AuthModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/profile`
export const LOGIN_URL = `${API_URL}/auth/admin/login`
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/admin/forgotPassword`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/admin/resetPassword`;
export const VERIFY_PASSWORD_LINK = `${API_URL}/auth/resetLink/`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {  email,
    password,
  }).then((response: any) => {
    return response
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resetPassword(password: string,email: string,) {
  return axios.put<{ result: any }>(RESET_PASSWORD_URL, {
    email,
    password
  })
}

export function getUserByToken(token: any) {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {})
}

export function verifyPasswordLink(id: string, otp: string) {
  return axios.get<{ result: any }>(`${VERIFY_PASSWORD_LINK}${id}?otp=${otp}`)
}

