/* eslint-disable @typescript-eslint/no-unused-vars */
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import Settings from "./setting";

const eventBreadcrumbs: Array<PageLink> = [
  {
    title: "Settings",
    path: "/settings",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const SettingPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={eventBreadcrumbs}>Settings</PageTitle>
      <Routes>
        <Route path="/" element={<Settings />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default SettingPage;
