import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_SUBSCRIPTIONS = `${API_URL}/adminWeb/subscriptions`;
const ADD_SUBSCRIPTION_DETAILS = `${API_URL}/adminWeb/subscription`;
const UPDATE_SUBSCRIPTION_DETAILS = `${API_URL}/adminWeb/subscription/`;

export const getSubscriptions = createAsyncThunk(
  "getSubscriptions",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '', sortOrder = "date" } = values
      const { data } = await axios.get(`${GET_ALL_SUBSCRIPTIONS}?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}`, {});      
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "updateSubscription",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${UPDATE_SUBSCRIPTION_DETAILS}${values.id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getSubscriptions({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addSubscription = createAsyncThunk(
  "addSubscription",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${ADD_SUBSCRIPTION_DETAILS}`, values);      
      notify(data.responseMessage, 'success');
      dispatch(getSubscriptions({ page: values.selectedPage || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "deleteSubscription",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${UPDATE_SUBSCRIPTION_DETAILS}${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getSubscriptions({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
