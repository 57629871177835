// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'

const subServicesColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-50px' />,
    id: 'name',
    // Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].name} />,
    Cell: ({ ...props }) => (
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span className="text-gray-800 mb-1">
            {props?.data[props.row.index]?.name ? props?.data[props.row.index]?.name : "--"}
          </span>
        </div>
      </div>
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Description' className='min-w-50px' />,
    id: 'description',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].description} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Service' className='min-w-50px' />,
    id: 'Service',
    Cell: ({ ...props }) => <TitleCell userObj={props?.data[props.row.index]?.serviceId?.name} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  }
]

export { subServicesColumns }
