/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCloseCreateFaqModal, setCloseDeleteFaqModal, setNewlyAddedFaq, updateFaq } from "./faqSlice";
import { notify, successMessage } from "../../../utils/shared";


const API_URL = process.env.REACT_APP_API_URL;
const FaqUrl = `${API_URL}/faq`;


export const FaqList = createAsyncThunk(
    "FaqList",
    async (_void, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.get(`${FaqUrl}/faqList`);
        return data;
      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        // notify(responseMessage, 'error');
        return rejectWithValue(error.message);
      }
    }
  );

  export const CreateFaq = createAsyncThunk(
    "getEvents",
    async (values: any, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.post(`${FaqUrl}/createFaq`,values);
        dispatch(setCloseCreateFaqModal());
        dispatch(setNewlyAddedFaq(data.data));
        successMessage(data.responseMessage);

        return data;

      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        // notify(responseMessage, 'error');
        return rejectWithValue(error.message);
      }
    }
  );

  export const UpdateFaq = createAsyncThunk(
    "UpdateFaq",
    async (item: any, { rejectWithValue, dispatch }) => {
      try {
        
        const { data } = await axios.put(`${FaqUrl}/updatefaq/${item.faq._id}`,item.values);
        dispatch(setCloseCreateFaqModal());
        dispatch(updateFaq(data.data));
        successMessage(data.responseMessage);

        return data;
      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        // notify(responseMessage, 'error');
        return rejectWithValue(error.message);
      }
    }
  );


  export const DeleteFaq = createAsyncThunk(
    "DeleteFaq",
    async (id: string, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.delete(`${FaqUrl}/deleteFaq/${id}`);
        dispatch(FaqList());
         successMessage(data.responseMessage);
         dispatch(setCloseDeleteFaqModal());
        return data;
      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        // notify(responseMessage, 'error');
        return rejectWithValue(error.message);
      }
    }
  );



