/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubscriptionModalStatus,
  setFiles,
  setFormDetails,
} from "../../../../redux/features/shared/sharedSlice";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "react-bootstrap";
import FieldInputText from "../common/InputFeilds/InputTextField";
import * as Yup from "yup";
import { EVENTS_TYPE, REQUIRED } from "../../../../utils/const";
import { toast } from "react-toastify";
import { LANG } from "../../../constants/language";
import {
  addServiceDetails,
  updateServiceDetails,
} from "../../../../redux/features/service/_serviceAction";
import Select from "react-select";
import { getCompanies } from "../../../../redux/features/company/_companyAction";
import { FALSE } from "sass";
import { useEffect, useState } from "react";
import { getPartners } from "../../../../redux/features/partner/_partnerAction";
import {
  addSubscription,
  updateSubscription,
} from "../../../../redux/features/subscription/_subscriptionAction";
import { getPlans } from "../../../../redux/features/plans/_plansAction";
import { GetSubscriptions } from "../../../../redux/features/payment/_paymentAction";

function SubscriptionModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [partnerlist, setPartners] = useState<any[]>([]);
  const partners: any =
    useSelector((state: any) => state.partnerList?.data) || [];
  const plansList: any =
    useSelector((state: any) => state.plansList?.data) || [];
  const companies: any =
    useSelector((state: any) => state.companyList?.data) || [];
  // const packages: any = useSelector((state: any) => state.plansList?.data)||[];
  const { subscriptions } = useSelector((state: any) => state.subscriptions);
  const subscriptionActions = useSelector(
    (state: any) => state.subscriptionActions
  );


  const companyFormValidation = Yup.object().shape({
    companyId: Yup.string().required(REQUIRED),
    packageId: Yup.string().required(REQUIRED),
    // creationDate: Yup.string().required(REQUIRED),
    startDate: Yup.string().required(REQUIRED),
    endDate: Yup.string().required(REQUIRED),
    partners: Yup.array()
      .min(1, "At least one partner is required")
      .required(REQUIRED),
    description: Yup.string(),
    max_subscribers: Yup.number().required(REQUIRED),
    price: Yup.number().required(REQUIRED),
  });

  const companyList = companies?.map((item: any) => ({
    value: item._id,
    label: item.companyName,
  }));

  const pacakgesList = plansList?.map((item: any) => ({
    value: item._id,
    label: item.name,
    price: item.price
  }));

  const options = partners?.map((item: any) => ({
    value: item._id,
    label: item.name,
    _id: item._id
  }));

  const formIntialValues = {
    companyId: sharedActions.formDetails.companyId || "",
    packageId: sharedActions.formDetails.packageId || "",
    // creationDate: sharedActions.formDetails.creationDate || "",
    startDate: sharedActions.formDetails.startDate || "",
    endDate: sharedActions.formDetails.endDate || "",
    partners: sharedActions.formDetails.partners || [],
    description: sharedActions.formDetails.description || "",
    max_subscribers: sharedActions.formDetails.max_subscribers || "",
    price: sharedActions.formDetails.price || "",
  };
  useEffect(() => {
    dispatch(getPlans({ page: 1, limit: 10 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPartners({ page: 1, limit: 10 }));
    dispatch(getCompanies({ page: 1, limit: 10 }));
    // dispatch(getPlans({ page: 1, limit: 10 }));
  }, []);

  useEffect(() => {
    if (sharedActions?.formDetails?.partners) {
      let preselectedPartners = sharedActions.formDetails.partners
        .map((partnerId) =>
          options.find((option) => option.value === partnerId._id)
        )
        .filter(Boolean);
      preselectedPartners = preselectedPartners?.map((item: any) => ({
        value: item._id,
        label: item.name,
        _id: item._id
      }));

      setPartners(preselectedPartners); // Update state
      eventFormik.setFieldValue("partners", sharedActions.formDetails.partners);
    }
  }, [sharedActions, partners]);

  const eventFormik: any = useFormik({
    initialValues: formIntialValues,
    validationSchema: companyFormValidation,
    onSubmit: (values: any, { resetForm }) => {
      if (sharedActions?.formDetails?._id) {
        dispatch(
          updateSubscription({
            ...values,
            id: sharedActions.formDetails?._id,
          })
        );
      } else {
        dispatch(addSubscription({ ...values }));
      }
      dispatch(setFormDetails({}));
      setTimeout(() => {
        dispatch(setSubscriptionModalStatus(false));
        resetForm();
      }, 500);
    },
    enableReinitialize: true,
  });

  const closeModal = () => {
    dispatch(setSubscriptionModalStatus(false));
    dispatch(setFormDetails({}));
    dispatch(setFiles([]));
  };

  const handleSelectChange = (selectedOptions: any) => {
    const selectedValues = partners
    .map((partner: any) => {
      const matchedPartner = selectedOptions.find((option: any) => option.value === partner._id);
      
      return matchedPartner || null;
    })
    .filter(Boolean);
    eventFormik.setFieldValue("partners", selectedValues);
    setPartners(selectedValues);
  };

  return (
    <>
      <Modal
        backdrop="static"
        size="lg"
        show={sharedActions.subscriptionStatusModal}
        onHide={closeModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!sharedActions.formDetails._id ? "Add" : "Update"} Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={eventFormik}>
            <Form onSubmit={eventFormik.handleSubmit} method="POST">
              <div className="row">
                <div className="col-sm-6 col-md-6 mb-6">
                  <Form.Label>
                    Company <span>*</span>
                  </Form.Label>
                  <Select
                    className={`custom-select-box ${
                      eventFormik.errors.companyId &&
                      eventFormik.touched.companyId
                        ? "is-invalid"
                        : ""
                    }`}
                    value={companyList.find(
                      (g) => g.value === eventFormik.values?.companyId
                    )}
                    name="companyId"
                    placeholder="Select Company"
                    onChange={(v) => {
                      eventFormik.setFieldValue("companyId", v?.value);
                    }}
                    options={companyList}
                  />
                  {eventFormik.errors.companyId &&
                    eventFormik.touched.companyId && (
                      <div className="invalid-feedback">
                        {eventFormik.errors.companyId}
                      </div>
                    )}
                </div>
                <div className="col-sm-6 col-md-6 mb-6">
                  <Form.Label>
                    Package <span>*</span>
                  </Form.Label>
                  <Select
                    className={`custom-select-box ${
                      eventFormik.errors.packageId &&
                      eventFormik.touched.packageId
                        ? "is-invalid"
                        : ""
                    }`}
                    value={pacakgesList.find(
                      (g) => g.value === eventFormik.values?.packageId
                    )}
                    name="packageId"
                    placeholder="Select Package"
                    onChange={(v) => {
                      eventFormik.setFieldValue("packageId", v?.value);
                      eventFormik.setFieldValue("price", v?.price);                      
                    }}
                    options={pacakgesList}
                  />
                  {eventFormik.errors.packageId &&
                    eventFormik.touched.packageId && (
                      <div className="invalid-feedback">
                        {eventFormik.errors.packageId}
                      </div>
                    )}
                </div>
                {/* <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="creationDate"
                      isRequired={false}
                      validate={companyFormValidation}
                      type="date"
                      label="Creation Date"
                      component={FieldInputText}
                    />
                    {eventFormik.errors.creationDate &&
                      eventFormik.touched.creationDate && (
                        <div className="invalid-feedback">
                          {eventFormik.errors.creationDate}
                        </div>
                      )}
                  </Form.Group>
                </div> */}
                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="startDate"
                      isRequired={true}
                      validate={companyFormValidation}
                      type="date"
                      label="Start Date"
                      component={FieldInputText}
                    />
                    {eventFormik.errors.startDate &&
                      eventFormik.touched.startDate && (
                        <div className="invalid-feedback">
                          {eventFormik.errors.startDate}
                        </div>
                      )}
                  </Form.Group>
                </div>
                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="endDate"
                      validate={companyFormValidation}
                      type="date"
                      label="End Date"
                      component={FieldInputText}
                      isRequired={true}
                    />
                    {eventFormik.errors.endDate &&
                      eventFormik.touched.endDate && (
                        <div className="invalid-feedback">
                          {eventFormik.errors.endDate}
                        </div>
                      )}
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="price"
                      validate={companyFormValidation}
                      min={0}
                      type="number"
                      label={"Price"}
                      component={FieldInputText}
                      isRequired={true}
                      readOnly={true}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="max_subscribers"
                      isRequired={true}
                      validate={companyFormValidation}
                      min={0}
                      type="number"
                      label={"Maximum number of subscribers"}
                      component={FieldInputText}
                    />
                    {eventFormik.errors.max_subscribers &&
                      eventFormik.touched.max_subscribers && (
                        <div className="invalid-feedback">
                          {eventFormik.errors.max_subscribers}
                        </div>
                      )}
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                
                <div className="col-sm-6 col-md-6 mb-6">
                  <div className="">
                    <Form.Label>
                      Partners <span>*</span>
                    </Form.Label>
                    <Select
                      isMulti
                      options={options}
                      value={options.filter((option) =>
                        eventFormik.values.partners.some(partner => partner._id === option._id)
                      )}
                      onChange={handleSelectChange}
                      placeholder="Select partners"
                      className={`react-select-container ${
                        eventFormik.errors.partners &&
                        eventFormik.touched.partners
                          ? "is-invalid"
                          : ""
                      }`}
                      classNamePrefix="react-select"
                    />
                    {eventFormik.errors.partners &&
                      eventFormik.touched.partners && (
                        <div className="invalid-feedback">
                          {eventFormik.errors.partners}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      as={"textarea"}
                      name="description"
                      validate={companyFormValidation}
                      label="Description"
                      component={FieldInputText}
                      maxLength={30}
                    />
                  </Form.Group>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  className="primaryBtn"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit" className="primaryBtn active">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { SubscriptionModal };
