export const LANG={
    SERVICE_NAME_REQUIRED: "Service name required",
    PARTNER:"Partner",
    USER:"User",
    UPDATE:"Update",
    ADD:"Add",
    CHANGE:"Change",
    NAME:"Name",
    EMAIL: "Email",
    FIRST_NAME:"First name",
    LAST_NAME:"Last name",
    PHONE:"Telephone",
    GENDER:"Gender",
    SELECT:"Choose",
    PASSWORD: "Password",
    BIRTHDAY:"Birthday",
    ROLE:"Role",
    CHECK_IN_RATE : "Check-in rate",
    BUSINESS_NAME: "Business name",
    BUSINESS_WEBSITE: "Business website",
    BUSINESS_LISTED_DATE: "Business listed date",
    BUSINESS_TYPE: "Business type",
    BUSINESS_CATEGORY: "Business category",
    SEVICES: "Services",
    STATUS: "Status",
    REJECTION_REASON: "Rejection reason",
    DESCRIPTION: "Description",
    ADDRESS:"Address",
    STREET:"Street",
    HOUSE_NO:"House number",
    ZIP_CODE:"ZIP code",
    CITY:"City",
    STATE:"State",
    BIRTHPLACE_CITY:"Place of birth",
    BIRTHPLACE_COUNTRY:"Country of birth",
    NATIONALITY:"Nationality",
    FIRSTNAME_PARENTS:"First name (guardian/parent)",
    LASTNAME_PARENTS:"Last name (guardian/parent)",
    EMAIL_PARENTS:"Email (guardian/parent)",
    PHONE_PARENTS:"Telephone (guardian/parent)",
    RELATION:"Relationship",
    BANK_NAME:"Name of the bank",
    ACCOUNT_HOLDER:"Name of account holder",
    APPLICATION_FOR_MATCH_PERMISSIONS:"Application for a gaming permit (Please request a gaming permit, download, fill out and upload)",
    APPLICATION_FOR_CLUB_TRANSFER:"Application to change club (please request permission to play, download, fill out and upload)",
    DOCTOR_CERTIFICATE:"Medical certificate",
    BIRTH_CERTIFICATE:"Copy of birth certificate",
    RESIDENCE_CERTIFICATION:"Proof of residence in the Federal Republic of Germany (confirmation from the registration authority)",
    PLAYER_PARENTS_DECLARATION:"Additional form to_players_parents can be found in the download area at www.hfv-online.de",
    COPY_OF_PASSPORT:"Copy of ID card/passport/identification document (residence permit/permit/permit)",
    APPLICATION_ATTACHMENT_ARGETINA:"The completed form can be found in the download area at www.hfv-online.de",
    APPLICATION_ATTACHMENT_ISTUPNICA:"Deregistration from the club (Istupnica)",
    DOCUMENTS:"Documents",
    APPLICATION_ATTACHMENT_BRISOVNICA:"Confirmation of deregistration from the responsible regional association (Brisovnica)",
    CANCEL:"Cancel",
    SUBMIT:"Submit",
    CHECKIN_RATE_REQUIRED:"check-in rate is required",
    LOCATIONS:"Locations",
    NO_LOCATIONS_AVAILABLE: "No locations available",
    BUSINESS_START_TIME: "Business start time",
    BUSINESS_END_TIME: "Business end time",
    SERVICES: "Services",
    WELCOME_BACK:"Welcome back",
    TRAINER:"Trainer",
    NO_LOCATION_IMAGES: "Location images are not available",
    DATE_CAN_NOT_BE_PAST:"The selected date must not be a past date",
    LIMIT_IS_EXCEEDED_FORM_MAX_LIMIT:"The limit was exceeded by the maximum limit",
    MEMBERS_IS_REQUIRED:"Members are required",
    
}