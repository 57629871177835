/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonTable } from "../common/common-list/table/Table";
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../common/common-list/components/pagination/Pagination";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import './style.scss';
import { UserListHeader } from "../common/common-list/components/header/UserListHeader";
import { format } from "date-fns";
import { getBookings } from "../../../../redux/features/bookings/_bookingsAction";
import { bookingsColumns } from "../common/common-list/table/columns/_bookingsColumns";

const BookingList = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch: any = useDispatch();
  const data: any = useSelector((state: any) => state.bookings?.data)||[];
  const totalRecord  = useSelector((state: any) => state.bookings?.totalRecord);

  // Calculate default date range (past 1 month)
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);
  // Format the dates before dispatching
  const formattedStart = oneMonthAgo ? format(oneMonthAgo, "yyyy-MM-dd") : null;
  const formattedEnd = today ? format(today, "yyyy-MM-dd") : null;

  useEffect(() => {
    dispatch(setId('Bookings'))
    dispatch(getBookings({ page: 1, limit: 10, sortOrder: sharedActions.sort, startDate: formattedStart, endDate: formattedEnd}));
  }, [dispatch, sharedActions.sort]);

  const handleClick = (page: number) => {
    dispatch(getBookings({ page: page, limit: 10, sortOrder: sharedActions.sort, startDate: sharedActions.startTime, endDate: sharedActions.endTime }));
  };

  return (
    <>
      <KTCard>
        <UserListHeader />
        <CommonTable data={data} columns={bookingsColumns} />
        {totalRecord > 10 && <Pagination
          totalRecord={totalRecord}
          handleClick={handleClick}
        />}
      </KTCard>
    </>
  );
};

export { BookingList };
