/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Button, Col, Row } from 'react-bootstrap';
import './style.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FAQForm from './FaqForm';
import { useDispatch, useSelector } from 'react-redux';
import { CreateFaq, DeleteFaq, FaqList, UpdateFaq } from '../../../../redux/features/faq/_faqActions';
import { setCloseCreateFaqModal, setCloseDeleteFaqModal, setOpenCreateFaqModal, setOpenDeleteFaqModal, setSelectedFaq } from '../../../../redux/features/faq/faqSlice';
import DeleteConfirmationModal from '../common/common-list/components/deleteConfirmationModel/deleteConfirmationModal';
const FAQ = () => {
    const dispatch: any = useDispatch();
    const faqActions = useSelector((state: any) => state.faqActions);
    const { faqs } = useSelector((state: any) => state.faqs);
    const { loading } = useSelector((state: any) => state.createFaq)
    const [title, setTitle]: any = useState('Add');

    useEffect(() => {
        dispatch(FaqList());
    }, []);


    const handleDelete = () => {

        dispatch(DeleteFaq(faqActions.selectedFaq._id))
    };

    const handleAddOrEdit = (faq: any, title: string) => {
        dispatch(setSelectedFaq(faq));
        setTitle(title);
        dispatch(setOpenCreateFaqModal());
    };

    const handleCloseModal = () => {
        dispatch(setCloseCreateFaqModal());
        dispatch(setSelectedFaq(null));

    };

    const handleSubmit = (values) => {
        if (faqActions.selectedFaq) {
            delete values._id
            let obj = {
                faq: faqActions.selectedFaq,
                values
            }
            dispatch(UpdateFaq(obj))
        }
        else { dispatch(CreateFaq(values)) };
    };

 
    return (
        <div>
            <Row className="align-items-center mb-3">
                <Col>
                    <h3>FAQ List</h3>
                </Col>
                <Col className="text-end">
                    {faqs.length !== 0 && <Button
                        variant="primary"
                        onClick={() => {
                            dispatch(setSelectedFaq(null))
                            dispatch(setOpenCreateFaqModal())
                            setTitle('Add')
                        }}
                    >
                        Add FAQ
                    </Button>}
                </Col>
            </Row>
            {faqs?.length === 0 ? (
                <div className="text-center my-5">
                    <h5 className="text-muted">
                        No FAQs yet? Let's Add the first one!
                    </h5>

                    <Button
                        variant="primary"
                        onClick={() => {
                            dispatch(setSelectedFaq(null));
                            dispatch(setOpenCreateFaqModal());
                            setTitle('Add');
                        }}
                    >
                        Add FAQ
                    </Button>
                </div>

            ) :
                <Accordion>
                    {faqs.map(faq => (
                        <Card key={faq._id}>
                            <Accordion.Item eventKey={faq._id}>
                                <Accordion.Header>
                                    {faq.question}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>{faq.answer}</p>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            size="sm"
                                            onClick={() => handleAddOrEdit(faq, 'Update')}
                                            className="me-2"
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => {
                                                dispatch(setOpenDeleteFaqModal())
                                                dispatch(setSelectedFaq(faq))
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Card>
                    ))}
                </Accordion>}
            <FAQForm
                showModal={faqActions.openCreateFaqModal}
                handleClose={handleCloseModal}
                onSubmit={handleSubmit}
                title={title}
                loading = {loading}
                initialValues={faqActions.selectedFaq || { question: '', answer: '' }} />

            {faqActions.openDeleteFaqModal && <DeleteConfirmationModal
                show={faqActions.openDeleteFaqModal}
                handleClose={() => dispatch(setCloseDeleteFaqModal())}
                handleDelete={handleDelete}
                item={faqActions.selectedFaq}
            />}
        </div>
    );
};

export default FAQ;