// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import { StatusCell } from './StatusCell'
import moment from 'moment'

const subscriptionColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Company Name' className='min-w-50px' />
    ),
    id: 'companyName',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.company?.companyName} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Creation Date' className='min-w-50px' />,
    id: 'creationDate',
    Cell: ({ ...props }) => <TitleCell userObj={moment(props.data[props.row.index]?.creationDate).format('DD/MM/YYYY')} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Package Id' className='min-w-50px' />,
    id: 'Package Name',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.package?.name } />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-50px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell userObj={props.data[props?.row?.index]} from="subscription"/>,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Price' className='min-w-50px' />,
    id: 'price',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.price} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  },
]

export { subscriptionColumns }
