import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubscribedAccountDetails,
} from "../../../../redux/features/shared/sharedSlice";
import { useEffect, useState } from "react";
import { deactivateSubscription, getSubscribedAccountDetails } from "../../../../redux/features/subscribedAccounts/_subscribedAccountsAction";
import { Button } from "react-bootstrap";

function SubscribedAccountDetailsModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);
  const [accountDetails, setAccountDetails] = useState<any>({});
  const subscriptionId = sharedActions?.formDetails?.subscriptionId;
  const employeeId = sharedActions?.formDetails?.employeeId;

  useEffect(() => {
    getAccountDetails();
  }, []);

  const getAccountDetails = async () => {
    const result: any = await dispatch(getSubscribedAccountDetails({ subscriptionId, employeeId }));

    if (result?.payload?.data) {
      setAccountDetails(result?.payload?.data);
    }
  };

  const deactivateSubscriptionPlan = () => {
    dispatch(deactivateSubscription({ subscriptionId, employeeId }));

    dispatch(setSubscribedAccountDetails(false));
  };

  const closeModal = () => {
    dispatch(setSubscribedAccountDetails(false));
  };

  return (
    <>
      <Modal
        className="wrapmodal"
        backdrop="static"
        size="lg"
        show={sharedActions.subscribedAccountDetailsModal}
        onHide={closeModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Details of Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapmodalInner">
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="half50">
                  <p>
                    <strong>Package Name:</strong>{" "}
                    {accountDetails?.packageName || "N/A"}
                  </p>
                  <p>
                    <strong>Company Name:</strong>{" "}
                    {accountDetails?.companyName || "N/A"}
                  </p>
                  <p>
                    <strong>Employee Name:</strong>{" "}
                    {accountDetails?.employeeName || "N/A"}
                  </p>
                  <p>
                    <strong>Subscribed Date:</strong>{" "}
                    {accountDetails?.subscribedDate || "N/A"}
                  </p>
                  <p>
                    <strong>Price:</strong>{" "}
                    {accountDetails?.price || "N/A"}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    {accountDetails?.status ? (accountDetails?.status?.toLowerCase() === 'inactive' ? 'Deactive' : accountDetails?.status?.charAt(0).toUpperCase() + accountDetails?.status?.slice(1)) : "N/A"}
                  </p>

                  <h4>Payment History</h4>

                  {accountDetails?.paymentDetails?.length && (
                    accountDetails?.paymentDetails?.map((paymentData, index) => (
                      <div key={index} className="location-section">
                        <p>
                          <strong>Payment ID:</strong>{" "}
                          {paymentData?.paymentId || "N/A"}
                        </p>
                        <p>
                          <strong>Status:</strong>{" "}
                          {paymentData?.status ? paymentData?.status.charAt(0).toUpperCase() + paymentData.status?.slice(1) : "N/A"}
                        </p>
                        <p>
                          <strong>Date:</strong>{" "}
                          {paymentData?.date || "N/A"}
                        </p>
                      </div>
                    ))
                  )}

                </div>
              </div>
            </div>
          </div>

          <Modal.Footer>
          {accountDetails?.status?.toLowerCase() !== 'inactive' && (
            <Button
              type="button"
              className="primaryBtn active"
              onClick={() => deactivateSubscriptionPlan()}
            >
              Deactivate Subscription
            </Button>
             )}

          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { SubscribedAccountDetailsModal };
