/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setFiles,
  setFormDetails,
  setEmployeeModalStatus,
} from "../../../../redux/features/shared/sharedSlice";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "react-bootstrap";
import FieldInputText from "../common/InputFeilds/InputTextField";
import * as Yup from "yup";
import { REQUIRED } from "../../../../utils/const";
import { updateEmployee } from "../../../../redux/features/employee/_employeeAction";
import moment from "moment";

function EmployeeModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);

  const employeeFormValidation = Yup.object().shape({
    firstName: Yup.string().required(REQUIRED),
    lastName: Yup.string().required(REQUIRED),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Please enter a valid email address'
      )
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(REQUIRED),
    phone: Yup.string(),
    dob: Yup.string(),
    companyName: Yup.string(),
    packageName: Yup.string(),
  });
  console.log('sharedActions.formDetails >>>>>>>>>. ', sharedActions.formDetails);

  const formIntialValues = {
    firstName: sharedActions.formDetails.firstName || "",
    lastName: sharedActions.formDetails.lastName || "",
    email: sharedActions.formDetails.email || "",
    phone: sharedActions.formDetails.phone || "",
    dob: sharedActions.formDetails.dob
      ? moment(sharedActions.formDetails.dob).format("YYYY-MM-DD")
      : "N/A",
    companyName: sharedActions.formDetails.companyName || "",
    packageName: sharedActions.formDetails?.packageName || "N/A",
  };

  const employeeFormik: any = useFormik({
    initialValues: formIntialValues,
    validationSchema: employeeFormValidation,
    onSubmit: (values: any, { resetForm }) => {
      if (sharedActions?.formDetails?._id) {
        console.log(values, ">>>>> values :::::")
        dispatch(
          updateEmployee({
            ...values,
            id: sharedActions.formDetails?._id,
          })
        );
      }
      dispatch(setFormDetails({}));
      setTimeout(() => {
        dispatch(setEmployeeModalStatus(false));
        resetForm();
      }, 500);
    },
    enableReinitialize: true,
  });

  const closeModal = () => {
    dispatch(setEmployeeModalStatus(false));
    dispatch(setFormDetails({}));
    dispatch(setFiles([]));
  };

  return (
    <>
      <Modal
        backdrop="static"
        size="lg"
        show={sharedActions.employeeStatusModal}
        onHide={closeModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!sharedActions.formDetails._id ? "Add" : "Update"} Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={employeeFormik}>
            <Form onSubmit={employeeFormik.handleSubmit} method="POST">
              <div className="row">

                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="firstName"
                      isRequired={true}
                      validate={employeeFormValidation}
                      type="text"
                      label="First Name"
                      component={FieldInputText}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="lastName"
                      isRequired={true}
                      validate={employeeFormValidation}
                      type="text"
                      label="Last Name"
                      component={FieldInputText}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="companyName"
                      // isRequired={true}
                      validate={employeeFormValidation}
                      type="text"
                      label="Company Name"
                      component={FieldInputText}
                      readOnly={true}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="email"
                      isRequired={true}
                      validate={employeeFormValidation}
                      type="text"
                      label="Email address"
                      component={FieldInputText}
                      readOnly={true}
                      maxLength={50}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="phone"
                      validate={employeeFormValidation}
                      min={0}
                      type="text"
                      label={"Phone"}
                      component={FieldInputText}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6 col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="dob"
                      isRequired={false}
                      validate={employeeFormValidation}
                      type="text"
                      label="Date of birth"
                      component={FieldInputText}
                      readOnly={true}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-6  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="packageName"
                      validate={employeeFormValidation}
                      type="text"
                      label="Package Name"
                      component={FieldInputText}
                      readOnly={true}
                    />
                  </Form.Group>
                </div>
              </div>

              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  className="primaryBtn"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit" className="primaryBtn active">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { EmployeeModal };
