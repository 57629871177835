/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Container, Accordion, Card } from 'react-bootstrap';
import FAQ from './faq';
import TermsAndConditions from './termandcondition';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSettingTab } from '../../../../redux/features/faq/faqSlice';
import Notifications from './notificationList';
import { useLocation } from 'react-router-dom';
// import FaqComponent from './FaqComponent'; // The FAQ CRUD component
// import PrivacyPolicy from './PrivacyPolicy';
// import TermsAndConditions from './TermsAndConditions';

const Settings = () => {
  const dispatch: any = useDispatch();
  const faqActions = useSelector((state: any) => state.faqActions);

  const location: any = useLocation();

  const activeTabFromLocation = location?.state?.activeTab || 'faq';

  useEffect(() => {
    setSelectedTab(activeTabFromLocation);
  }, [activeTabFromLocation, dispatch]);


  const setSelectedTab = (tab: string) => {
    dispatch(setSelectedSettingTab(tab))
  }

  return (
    <Container className="mt-4">
      <h1>Settings</h1>
      <Tabs
        id="settings-tabs"
        activeKey={faqActions?.settingSeletedTab}
        onSelect={(k: any) => setSelectedTab(k)}
        className="mb-3"
      >
        <Tab eventKey="faq" title="FAQs">
          <FAQ />
        </Tab>

        <Tab eventKey="terms" title="Terms and Conditions">
          <TermsAndConditions />
        </Tab>

        <Tab eventKey="notifications" title="Notifications">
          <Notifications />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Settings;
