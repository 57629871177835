import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_CLASSES = `${API_URL}/adminWeb/allClass`;
const ADD_CLASS_DETAILS = `${API_URL}/adminWeb/addClass`;
const UPDATE_ClASS_DETAILS = `${API_URL}/adminWeb/editClass/`;
const DELETE_CLASS = `${API_URL}/adminWeb/class/`
export const getClasses = createAsyncThunk(
  "getClasses",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '', sortOrder = 'date' } = values
      const { data } = await axios.get(`${GET_ALL_CLASSES}?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}`, {});      
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const editClass = createAsyncThunk(
  "editClass",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { payload, id } = values;
      const { data } = await axios.put(`${UPDATE_ClASS_DETAILS}${id}`, payload);
      notify(data.responseMessage, 'success');
      dispatch(getClasses({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addClass = createAsyncThunk(
  "addClass",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      console.log(values,">>>> value :::")
      const { data } = await axios.post(`${ADD_CLASS_DETAILS}`, values);      
      notify(data.responseMessage, 'success');
      dispatch(getClasses({ page: values.selectedPage || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const deleteClass = createAsyncThunk(
  "deleteClass",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${DELETE_CLASS}${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getClasses({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
