import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_PARTNER_ANALYTICS = `${API_URL}/adminWeb/partnerAnalytics`;

export const getPartnerAnalytics = createAsyncThunk(
  "getPartnerAnalytics",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '',  sortOrder = 'date', startDate, endDate, download = "false"} = values
      const { data } = await axios.get(`${GET_PARTNER_ANALYTICS}?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}&download=${download}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


