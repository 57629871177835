
import { createSlice } from "@reduxjs/toolkit";
import { CreateTerm, TermsList } from "./_termAction";

const initialState: any = {
    terms: [],
    loading: false,
    openCreateTermsModal: false,
    selectedTerm: null,
    openDeleteTermModal:false
};

export const termsActions = createSlice({
    name: "terms",
    initialState: initialState,
    reducers: {
        setOpenCreateTermModal: (state) => {
            state.openCreateTermsModal = true
        },
        setCloseCreateTermModal: (state) => {
            state.openCreateTermsModal = false
        },
        
        setOpenDeleteTermModal: (state) => {
            state.openDeleteTermModal = true
        },
        setCloseDeleteTermModal: (state) => {
            state.openDeleteTermModal = false
        },

        setSelectedTerm: (state,{payload}) => {
            state.selectedTerm = payload;
        }
    }
});

export const terms = createSlice({
    name: "terms",
    initialState: initialState,
    reducers: {
        setNewlyAddedTerm: (state, { payload }) => {
            state.terms = [...state.terms, payload]
        },

        updateTerm: (state, { payload }) => {
            let data = payload
            let terms = [...state.terms];
            let index =  terms.findIndex((e:any)=> e._id === data._id);
            if(index !== -1) terms[index] = data;
            state.terms = terms;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(TermsList.pending, (state) => {
                state.loading = true;
            })
            .addCase(TermsList.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.terms = payload.data || [];

            })
            .addCase(TermsList.rejected, (state, { payload }) => {
                state.loading = false;

            })
    },
});


export const createTerm = createSlice({
    name: "createTerm",
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(CreateTerm.pending, (state) => {
                state.loading = true;
            })
            .addCase(CreateTerm.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(CreateTerm.rejected, (state, { payload }) => {
                state.loading = false;

            })
    },
});

export const {setOpenCreateTermModal, setCloseCreateTermModal, setOpenDeleteTermModal,setCloseDeleteTermModal,setSelectedTerm} = termsActions.actions;

export const {setNewlyAddedTerm,updateTerm}  = terms.actions