import { UsersListToolbar } from "./ListToolbar";
import { CommonListSearchComponent } from "./ListSearchComponent";
import ExportIcon from "../../../../../../icons/ExportIcon";
import VisibilityBox from "../../../visibility-box/VisibilityBox";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { SortOrder } from "./SortOrder";
import { SortYearDropdown } from "./SortYearDropdown";
import { DateRangeSelector } from "./DateRangeSelector";
import { DownloadCSVButton } from "./DownloadCSVButton";
import { useState } from "react";
import { format } from "date-fns"; // For date formatting
import { commonDateRangeFilterCases } from "../../../../../../../utils/shared";

const UserListHeader = ({ exportObject }: any) => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch = useDispatch();

  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);
  const [startDate, setStartDate] = useState<Date | null>(oneMonthAgo);
  const [endDate, setEndDate] = useState<Date | null>(today);


  // Function to update the date range state
  const handleDateRangeChange = (start: Date | null, end: Date | null) => {
    setStartDate(start);
    setEndDate(end);

    // Format the dates before dispatching
    const formattedStart = start ? format(start, "yyyy-MM-dd") : null;
    const formattedEnd = end ? format(end, "yyyy-MM-dd") : null;

    console.log("Selected Date Range (Formatted):", { formattedStart, formattedEnd });

    // Dispatch the formatted date range
    commonDateRangeFilterCases(sharedActions.id, formattedStart, formattedEnd, dispatch);
  };

  return (
    <>
      <div className="card-header border-0 pt-6 justify-content-end">
        <div className="card-title">
          <DateRangeSelector onDateChange={handleDateRangeChange} />
        </div>

        {(sharedActions.id !== "SubscribedAccounts" && sharedActions.id !== "PartnerAnalytics") && (
          <div className="card-title">
            <SortOrder />
          </div>
        )}

        {/* <div>
          <SortYearDropdown />
        </div> */}

        {(sharedActions.id !== "Plans" && sharedActions.id !== "SubscribedAccounts" && sharedActions.id !== "PartnerAnalytics"  && sharedActions.id !== "Bookings" && sharedActions.id !== "CustomReport") && (
          <CommonListSearchComponent />
        )}

        {sharedActions.id !== "Partner" && (
          <div className="card-toolbar">
            <UsersListToolbar />
          </div>
        )}

        <div className="card-title">
          <DownloadCSVButton startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </>
  );
};

export { UserListHeader };
