/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonTable } from "../common/common-list/table/Table";
import { KTCard } from "../../../../_metronic/helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../common/common-list/components/pagination/Pagination";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import { PlansModal } from "./PlansModal";
import './style.scss';
import { UserListHeader } from "../common/common-list/components/header/UserListHeader";
import { plansColumns } from "../common/common-list/table/columns/_plansColumns";
import { getPlans } from "../../../../redux/features/plans/_plansAction";
import SubscriptionByPayMobForm from "../subscriptionByPaymob/subscriptionByPaymobForm";
import { CreateSubscription, GetSubscriptions } from "../../../../redux/features/payment/_paymentAction";
import { setSelectedTerm } from "../../../../redux/features/terms/termSlice";
import { setCloseCreateSubscriptionPlanModal } from "../../../../redux/features/payment/paymentSlice";
const PlansList = () => {
  const dispatch: any = useDispatch();
  const totalRecord  = useSelector((state: any) => state.plansList?.totalRecord);
  const planList = useSelector((state: any) => state?.plansList?.data);
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const subscriptionActions = useSelector((state: any) => state.subscriptionActions);
  useEffect(() => {
    dispatch(setId('Plans'))
    dispatch(getPlans({page: 1, limit:20 }));
  }, [dispatch]);

  const handleClick = (page: number) => {
    dispatch(getPlans({ page: page, limit: 10 }));
  };

  return (
    <>
      <KTCard>
        <UserListHeader />
        <CommonTable data={planList} columns={plansColumns} />
        {sharedActions?.plansModal &&  
          <PlansModal />
      //   <SubscriptionByPayMobForm
      //   showModal={subscriptionActions?.openCreateSubscriptionPlanModal}
      //   handleClose={handleCloseModal}
      //   onSubmit={handleSubmit}
      //   title={title}
      //   loading={loading}
      //   initialValues={
      //     subscriptionActions?.selectedSubscription || {
      //       frequency: 30,
      //       name: '',
      //       amount_cents: null,
      //       is_active: true,
      //     }
      //   }
      // />
      }
        {totalRecord > 10 && <Pagination
          totalRecord={totalRecord}
          handleClick={handleClick}
        />}
      </KTCard>
    </>
  );
};

export { PlansList };
