import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_SUBSCRIBED_ACCOUNT = `${API_URL}/adminWeb/subscribedAccountList`;
const GET_SUBSCRIBED_ACCOUNT_DETAILS = `${API_URL}/adminWeb/subscribedAccount/`;
const DEACTIVATE_SUBSCRIBED_ACCOUNT = `${API_URL}/adminWeb/deactivateSubscribedAccount/`;

export const getSubscribedAccounts = createAsyncThunk(
  "getSubscribedAccounts",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '', sortOrder = 'date', startDate, endDate, download = "false" } = values
      const { data } = await axios.get(`${GET_ALL_SUBSCRIBED_ACCOUNT}?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}&download=${download}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const getSubscribedAccountDetails = createAsyncThunk(
  "getSubscribedAccountDetails",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(`${GET_SUBSCRIBED_ACCOUNT_DETAILS}${values.subscriptionId}?employeeId=${values.employeeId}`, {});      
      // notify(data.responseMessage, 'success');
      dispatch(getSubscribedAccounts({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const deactivateSubscription = createAsyncThunk(
  "deactivateSubscription",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${DEACTIVATE_SUBSCRIBED_ACCOUNT}${values.subscriptionId}?employeeId=${values.employeeId}`, {});      
      notify(data.responseMessage, 'success');
      dispatch(getSubscribedAccounts({ page: values.selectedPage || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


