
import { createSlice } from "@reduxjs/toolkit";
import { CreateFaq, FaqList } from "./_faqActions";

const initialState: any = {
    faqs: [],
    loading: false,
    openCreateFaqModal: false,
    selectedFaq: null,
    openDeleteFaqModal:false,
    settingSeletedTab:"faq"
};

export const faqActions = createSlice({
    name: "faqs",
    initialState: initialState,
    reducers: {
        setOpenCreateFaqModal: (state) => {
            state.openCreateFaqModal = true
        },
        setCloseCreateFaqModal: (state) => {
            state.openCreateFaqModal = false
        },
        
        setOpenDeleteFaqModal: (state) => {
            state.openDeleteFaqModal = true
        },
        setCloseDeleteFaqModal: (state) => {
            state.openDeleteFaqModal = false
        },

        setSelectedFaq: (state,{payload}) => {
            state.selectedFaq = payload;
        },
        setSelectedSettingTab:(state,{payload})=>{
            state.settingSeletedTab = payload;
        }
    }
});

export const faqs = createSlice({
    name: "faqs",
    initialState: initialState,
    reducers: {
        setNewlyAddedFaq: (state, { payload }) => {
            state.faqs = [...state.faqs, payload]
        },

        updateFaq: (state, { payload }) => {
            let data = payload
            let faqs = [...state.faqs];
            let index =  faqs.findIndex((e:any)=> e._id === data._id);
            if(index !== -1) faqs[index] = data;
            state.faqs = faqs;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(FaqList.pending, (state) => {
                state.loading = true;
            })
            .addCase(FaqList.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.faqs = payload.data || [];

            })
            .addCase(FaqList.rejected, (state, { payload }) => {
                state.loading = false;

            })
    },
});


export const createFaq = createSlice({
    name: "createFaq",
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(CreateFaq.pending, (state) => {
                state.loading = true;
            })
            .addCase(CreateFaq.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(CreateFaq.rejected, (state, { payload }) => {
                state.loading = false;

            })
    },
});

export const {setOpenCreateFaqModal, setCloseCreateFaqModal,setSelectedFaq,setOpenDeleteFaqModal,setCloseDeleteFaqModal,setSelectedSettingTab} = faqActions.actions;

export const {setNewlyAddedFaq,updateFaq}  = faqs.actions