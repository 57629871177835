// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'

const classColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Class Name' className='min-w-50px' />
    ),
    id: 'className',
    Cell: ({ ...props }) => {
      const toTitleCase = (str) =>
        str.replace(/\b\w/g, (char) => char.toUpperCase());
    
      return (
        <TitleCell
          userObj={toTitleCase(props.data[props.row.index]?.className || "")}
        />
      );
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Service' className='min-w-50px' />,
    id: 'service',
    Cell: ({ ...props }) => {
      const toTitleCase = (str) =>
        str.replace(/\b\w/g, (char) => char.toUpperCase());
    
      return (
        <TitleCell
          userObj={toTitleCase(props.data[props.row.index]?.services?.name || "")}
        />
      );
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Maximum Bookings' className='min-w-50px' />,
    id: 'maxBooking',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].maxBooking} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Class Status' className='min-w-50px' />,
    id: 'classStatus',
    Cell: ({ ...props }) => {
          const classStatus = props.data[props.row.index].classStatus ? 'Active': 'In Active';
          return <TitleCell userObj={classStatus} />;
        },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  },
]

export { classColumns }
