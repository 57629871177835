/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { KTIcon } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';
import { useAuth } from '../../../../app/modules/auth';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../../../redux/features/notification/_notificationAction';
import { toast } from 'react-toastify';
import {
  closeNotificationModal,
  incrementNotificationCount,
  openNotificationModal,
  resetNotificationCount,
} from '../../../../redux/features/notification/notificationSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import moment from 'moment';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1';

const Topbar: FC = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    data: notificationList = [],
    totalRecord,
    notificationCount,
    openNotificationListModal,
  } = useSelector((state: any) => state.notificationList);
 
  const socketUrl = process.env.SOCKET_URL || 'http://localhost:9000';

  useEffect(() => {
    dispatch(getNotifications({ page: 1, limit: 10 }));
    

    const socket = io(socketUrl , {
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('Connected to WebSocket:', socket.id);
    });

    socket.on('adminNotification', (data: any) => {
      console.log('Notification received:', data);

      dispatch(getNotifications({ page: 1, limit: 10 }));

      // Increment notification count and show toast
      dispatch(incrementNotificationCount());
      toast.success(`${data?.message}`);
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
    });

    socket.on('disconnect', (reason) => {
      console.log('WebSocket disconnected:', reason);
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        dispatch(closeNotificationModal());
      }
    };

    // Close modal on route change
    const handleRouteChange = () => {
      dispatch(closeNotificationModal());
    };

    // Listen for route changes
    handleRouteChange();

    // Listen to clicks outside the modal
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dispatch, location]);

  const handleViewAllClick = () => {
    dispatch(closeNotificationModal());

    // Navigate to settings page
    navigate('/settings', {
      state: { activeTab: 'notifications' },
    });
  };

  return (
    <div className='d-flex align-items-stretch flex-shrink-0 me-5'>
      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          onClick={() => {
            dispatch(resetNotificationCount());
            dispatch(openNotificationModal());
          }}
        >
          <KTIcon iconName='notification' className={toolbarButtonIconSizeClass} />
          {notificationCount > 0 && (
            <span
              className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
              style={{ fontSize: '0.75rem', lineHeight: '1rem' }}
            >
              {notificationCount}
            </span>
          )}
        </div>

        {openNotificationListModal && (
          <div
            ref={modalRef}
            className="notification-dropdown position-absolute top-100 end-0 mt-2 p-3 bg-white border rounded shadow-lg"
            style={{
              width: '300px',
              borderRadius: '10px',
              backgroundColor: '#fff',
              boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
              marginRight: '25px',
            }}
          >
            <h5
              className="text-center"
              style={{
                color: '#2194FF',
                fontWeight: 'bold',
                borderBottom: '2px solid #2194FF',
                paddingBottom: '10px',
                marginBottom: '5px',
                marginTop: '10px',
              }}
            >
              Notifications ({totalRecord})
            </h5>

            <ListGroup>
              <div className='scroll-y mh-325px my-5 px-8'>
                {notificationList?.length > 0 ? (
                  notificationList.map((notification: any, index: number) => (
                    <div key={`notification-${index}`} className='d-flex flex-stack py-4'>

                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='mb-0 me-2'>
                          <div className='fs-6 text-gray-800 fw-bolder'>
                            {notification?.message?.title}
                          </div>
                          <div className='text-gray-400 fs-7 mb-2'>{notification?.message?.body}</div>

                          <span className='badge-light fs-8'>
                            {moment(notification?.createdAt).format('MM/DD/YYYY - hh:mm A')}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>No notifications available</div>
                )}
              </div>

            </ListGroup>

            {notificationList?.length > 0 && (
              <div className='py-3 text-center border-top'>
                <button
                  onClick={handleViewAllClick}
                  className='btn btn-color-gray-600 btn-active-color-primary'
                >
                  View All <KTIcon iconName='arrow-right' className='fs-5' />
                </button>
              </div>
            )}

          </div>
        )}
      </div>


      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center mr-3', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <span className='font-weight-bold btn border-1 btn btn-outline text-white'>
            {currentUser?.name || "Admin"}
          </span>
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic' /> */}
        </div>
        <HeaderUserMenu />
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTIcon iconName='text-align-left' className='fs-1' />
          </div>
        </div>
      )}


    </div>
  );
};

export { Topbar };
