/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify, successMessage } from "../../../utils/shared";
import { setCloseCreateSubscriptionPlanModal } from "./paymentSlice";


const API_URL = process.env.REACT_APP_API_URL;
const SubscriptionUrl = `${API_URL}/subscription`;



  export const CreateSubscription = createAsyncThunk(
    "CreateSubscription",
    async (values: any, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.post(`${SubscriptionUrl}/createSubscription`,values);
        dispatch(setCloseCreateSubscriptionPlanModal())
        dispatch(GetSubscriptions({page: values.selectedPage || 1, limit:10}))
        successMessage(data.responseMessage);
        return data;

      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        return rejectWithValue(error.message);
      }
    }
  );

  export const GetSubscriptions = createAsyncThunk(
    "CreateTerm",
    async (values:any, { rejectWithValue, dispatch }) => {
      try {
        const { page, limit, search = '' } = values
        const { data } = await axios.get(`${SubscriptionUrl}/getSubscriptions?page=${page}&limit=${limit}&search=${search}`);
        return data;

      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        return rejectWithValue(error.message);
      }
    }
  );









