import { configureStore } from "@reduxjs/toolkit";
import { userList } from "./features/user/userSlice";
import { eventList } from "./features/event/eventSlice";
import logger from "redux-logger";
import { sharedSlice } from "./features/shared/sharedSlice";
import { serviceList } from "./features/service/serviceSlice";
import { wellnessTypesList } from "./features/wellnessTypes/wellnessTypesSlice";
import { subServicesList } from "./features/subServices/subServicesSlice";
import { companiesList } from "./features/company/companySlice";
import { partnerList } from "./features/partner/partnerSlice";
import { subscriptionList } from "./features/subscription/subscriptionSlice";
import { plansList } from "./features/plans/plans";
import { employeeList } from "./features/employee/employeeSlice";
import { createFaq, faqActions, faqs } from "./features/faq/faqSlice";
import { createTerm, terms, termsActions } from "./features/terms/termSlice";
import { getSubscriptions, subscriptionActions } from "./features/payment/paymentSlice";
import { notificationList } from "./features/notification/notificationSlice";
import { dashboardData } from "./features/dashboard/dashboardSlice";
import { subscriptionAccounts } from "./features/subscribedAccounts/subscribedAccountsSlice";
import { partnerAnalytics } from "./features/partnerAnalytics/partnerAnalyticsSlice";
import { classesList } from './features/classes/classesSlice';
import { bookings } from "./features/bookings/bookingsSlice";
import { financialReport } from "./features/financialReport/financialReportSlice";


export const store = configureStore({
  reducer: {
    userList: userList.reducer,
    eventList: eventList.reducer,
    sharedActions: sharedSlice.reducer,
    serviceList: serviceList.reducer,
    wellnessTypesList: wellnessTypesList.reducer,
    subServices: subServicesList.reducer,
    companyList: companiesList.reducer,
    partnerList: partnerList.reducer,
    subscriptionList: subscriptionList.reducer,
    plansList: plansList.reducer,
    employeesList: employeeList.reducer,
    faqs: faqs.reducer,
    createFaq: createFaq.reducer,
    faqActions:faqActions.reducer,
    termsActions:termsActions.reducer,
    terms:terms.reducer,
    createTerm:createTerm.reducer,
    subscriptions:getSubscriptions.reducer,
    subscriptionActions:subscriptionActions.reducer,
    notificationList: notificationList.reducer,
    dashboardData: dashboardData.reducer,
    subscriptionAccounts: subscriptionAccounts.reducer,
    partnerAnalytics: partnerAnalytics.reducer,
    classesList: classesList.reducer,
    bookings: bookings.reducer,
    financialReport: financialReport.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).concat(logger as any),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
