/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Form } from "react-bootstrap";
import FileIcon from "../../../icons/FileIcon";
import AngleWhiteTopIcon from "../../../icons/AngleWhiteTopIcon";
import CrossWhiteBlackIcon from "../../../icons/CrossWhiteBlackIcon";
import { Link, useNavigate } from "react-router-dom";
import TimerIcon from "../../../icons/TimerIcon";
import GroupUsersIcon from "../../../icons/GroupUsersIcon";
import { useFormik } from "formik";
// import { addClass, editClass } from "../../../services/classes.service";
// import { getAllSubServices } from "../../../services/subServices.service";
import Select from 'react-select';
import moment from "moment";
import { MIME_TYPE_MAP } from "../../../constants/utlis";
import { setClassesModalStatus, setFiles, setFormDetails } from "../../../../redux/features/shared/sharedSlice";
import ErrorText from "../common/common-list/components/error-text";
import { getServices } from "../../../../redux/features/service/_serviceAction";
import { getSubServices } from "../../../../redux/features/subServices/_subServicesAction";
import { getPartners } from "../../../../redux/features/partner/_partnerAction";
import { addClass, editClass } from "../../../../redux/features/classes/_classesAction";
import { getPartnerById } from "../../../../services/user.service";
// import ErrorText from "../error-text";

function ClassesModal(params: any) {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const [count, setCount] = useState(1);
  const [afterOccurencesCount, setAfterOccurencesCount] = useState(1)
  const [loading, setLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const user = useSelector((state: any) => state.user);
  const [servicelist, setServicelist] = useState<any[]>([]);
  const [startTime, setStartTime] = useState<any>('')
  const [endTime, setEndTime] = useState<any>('')
  const [subServicelist, setSubServicelist] = useState<any[]>([]);
  const [endTimeFormat, setEndTimeFormat] = useState<string>('');
  const [startTimeFormat, setStartTimeFormat] = useState<string>('');
  const subServices: any = useSelector((state: any) => state.subServices?.data) || [];
  const partners: any =
    useSelector((state: any) => state.partnerList?.data) || [];
  const navigate = useNavigate();
  const [partnerLocations, setPartnerLoactions] = useState<any>([]);
  console.log(sharedActions?.formDetails, ">>>> sharedActions?.formDetails? on First Render")
  const classInitialValues: any = {
    classStatus: (sharedActions?.formDetails?.classStatus === true),
    subServiceId: sharedActions?.formDetails?.subServiceId || null,
    partnerId: sharedActions?.formDetails?.partnerId || null,
    partnerLocation: sharedActions?.formDetails?.partnerLocation || null,
    className: sharedActions?.formDetails?.className || null,
    description: sharedActions?.formDetails?.description || "",
    // bookingInstruction: sharedActions?.formDetails?.bookingInstruction || "",
    classStartDate: sharedActions?.formDetails?.classTime?.date || null,
    classStartTime: sharedActions?.formDetails?.classTime?.start || "",
    classEndTime: sharedActions?.formDetails?.classTime?.end || "",
    classRepeatType: sharedActions?.formDetails?.classRepeat?.type || null,
    classRepeatCount: sharedActions?.formDetails?.classRepeatCount || 0,
    classSelection: sharedActions?.formDetails?.classData?.classSelection || "",
    classWeekdays: sharedActions?.formDetails?.classRepeat?.selection ? sharedActions?.formDetails?.classRepeat?.selection : null,
    classEndType: sharedActions?.formDetails?.classEnd?.type ? sharedActions?.formDetails?.classEnd?.type : null,
    classEndDate: params?.classData?.classEndDate || moment().add(1, "days").format("YYYY-MM-DD"),
    classEndOccurence: sharedActions?.formDetails?.classEndOccurence || 0,
    maxBooking: sharedActions?.formDetails?.maxBooking || null,
    images: sharedActions?.formDetails?.images || [],
    // canReschedule: (sharedActions?.formDetails?.canReschedule === true),
    // canCancel: (sharedActions?.formDetails?.canCancel === true),
    cancellationUpto: sharedActions?.formDetails?.cancellationUpto || 0
  };

  const classSchema: any = Yup.object().shape({
    classStatus: Yup.string().optional(),
    subServiceId: Yup.string().required("Field is required"),
    partnerId: Yup.string().required("Field is required"),
    partnerLocation: Yup.string().required("Field is required"),
    className: Yup.string().required("Field is required"),
    classStartDate: Yup.string().required("Field is required"),
    classStartTime: Yup.string().required("Field is required"),
    classEndTime: Yup.string().required("Field is required"),
    classRepeatType: Yup.string().required("Field is required"),
    classWeekdays: Yup.string().required("Field is required"),
    classEndType: Yup.string().required("Field is required"),
    maxBooking: Yup.number().required("Field is required")
  });

  const daysOfWeek = [
    { id: "everyday", name: "Everyday" },
    { id: "monday", name: "Monday" },
    { id: "tuesday", name: "Tuesday" },
    { id: "wednesday", name: "Wednesday" },
    { id: "thursday", name: "Thursday" },
    { id: "friday", name: "Friday" },
    { id: "saturday", name: "Saturday" },
    { id: "sunday", name: "Sunday" },
  ];

  const slotOptions = ["day", "week", "month"];

  useEffect(() => {
    dispatch(getPartners({ page: 1, limit: 10 }));
    dispatch(getSubServices({}))
  }, [])

  useEffect(() => {
    // 
  }, [setStartTimeFormat, setEndTimeFormat])

  useEffect(() => {
    if (sharedActions?.formDetails?._id) {

      if (sharedActions?.formDetails?.images && sharedActions?.formDetails?.images?.length > 0) {
        let previewImgArr: any = [];
        for (let item of sharedActions?.formDetails?.images) {
          const extension = item.split('.').pop().toLowerCase();
          let mimeType = MIME_TYPE_MAP[extension] || 'application/octet-stream';

          previewImgArr.push({
            preview: item,
            type: mimeType,
          })
        }
        setPreviews([...previewImgArr]);
        setImages([...sharedActions?.formDetails?.images]);
      }

      if (sharedActions?.formDetails?.subServiceId) {
        let item: any = subServicelist.find(item => item._id == sharedActions?.formDetails?.subServiceId)
        if (item?._id) {
          formik.setFieldValue("subServiceId", item._id)
        }
      }

      if (sharedActions?.formDetails?.partnerLocation) {
        fetchAllLoactions(sharedActions?.formDetails?.partnerId);
        let item: any = partnerLocations.find(item => item._id == sharedActions?.formDetails?.partnerLocation)
        if (item?._id) {
          formik.setFieldValue("partnerLocation", item._id)
        }
      }

      if (sharedActions?.formDetails?.classRepeatCount) {
        let number = parseInt(sharedActions?.formDetails?.classRepeatCount)
        if (!isNaN(number)) {
          setCount(number)
        }
      }

      if (sharedActions?.formDetails?.classRepeat?.weekDays) {
        const weekDays = sharedActions?.formDetails?.classRepeat?.weekDays;
        if (weekDays.includes("everyday")) {
          setSelectedDays(["everyday"]);
        } else {
          setSelectedDays(weekDays);
        }
      }

      if (sharedActions?.formDetails?.classEnd?.NoOfOccurence && sharedActions?.formDetails?.classEnd?.type === "after") {
        setAfterOccurencesCount(sharedActions?.formDetails?.classEnd?.NoOfOccurence)
      }

      if (sharedActions?.formDetails?.classTime?.start && sharedActions?.formDetails?.classTime?.end) {
        const amOrPmMorning = moment(sharedActions?.formDetails?.classTime?.start, 'HH:mm').format('A');
        const amOrPmEvening = moment(sharedActions?.formDetails?.classTime?.end, 'HH:mm').format('A');
        setStartTimeFormat(amOrPmMorning);
        setEndTimeFormat(amOrPmEvening);
        setStartTime(sharedActions?.formDetails?.classTime?.start);
        setEndTime(sharedActions?.formDetails?.classTime?.end);
      }
    }
  }, [params]);

  function increment(type = "repeat") {
    if (type === "end") {
      setAfterOccurencesCount(function (prevCount) {
        return (prevCount += 1);
      });
    } else {
      setCount(function (prevCount) {
        return (prevCount += 1);
      });
    }
  }

  function decrement(type = "repeat") {
    if (type === "end") {
      setAfterOccurencesCount(function (prevCount) {
        if (prevCount > 0) {
          return (prevCount -= 1);
        } else {
          return (prevCount = 0);
        }
      });
    }
    else {
      setCount(function (prevCount) {
        if (prevCount > 0) {
          return (prevCount -= 1);
        }
        else {
          return (prevCount = 0);
        }
      });
    }
  }

  const closeModal = () => {
    dispatch(setClassesModalStatus(false));
    dispatch(setFormDetails({}));
    dispatch(setFiles([]));
  };

  const fetchAllLoactions = async (id: string) => {
    let parnterDetails = await getPartnerById(id);
    if (parnterDetails?.data?.locations && parnterDetails?.data?.locations?.length > 0) {
      setPartnerLoactions(parnterDetails?.data?.locations)
    }
  }

  const handleDayCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    const updatedDays = checked
      ? [...selectedDays, value]
      : selectedDays.filter((day) => day !== value);

    if (value === "everyday" && checked) {
      setSelectedDays(["everyday"]);
    } else if (value === "everyday" && !checked) {
      setSelectedDays([]);
    } else {
      const filteredDays = updatedDays.filter((day) => day !== "everyday");
      setSelectedDays(filteredDays);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const selectedPreviews = selectedFiles.map((file) =>
    ({
      preview: URL.createObjectURL(file),
      type: file.type, // Save the MIME type
    }));

    setImages((prev) => [...prev, ...selectedFiles]);
    setPreviews((prev: any) => [...prev, ...selectedPreviews]);
  };

  const handleRemoveImage = (index: number) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
    setPreviews((prev) => prev.filter((_, i) => i !== index));
  };

  function isLiveUrl(filename: any) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.mp4', '.gif'];
    const extension = filename.slice(filename.lastIndexOf('.')).toLowerCase();
    if (imageExtensions.includes(extension)) {
      return true;
    } else {
      return false
    }
  }

  const formik: any = useFormik({
    initialValues: classInitialValues,
    validationSchema: classSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      let classRepeat: any = {
        repeatCount: count,
      }
      let classEnd: any = {
      }
      let classTime: any = {
      }

      if (values.classRepeatType === "repeat") {
        classRepeat.type = "repeat";
      }
      if (values.classRepeatType == "doesNotRepeat") {
        classRepeat.type = "doesNotRepeat";
      }
      if (values.classWeekdays) {
        classRepeat.selection = values.classWeekdays;
        if (
          values.classWeekdays === "week" &&
          selectedDays &&
          selectedDays.length > 0
        ) {
          classRepeat.weekDays = selectedDays;
        }
      }

      if (values.classEndType) {
        classEnd.type = values.classEndType
        if (values.classEndType === "on") {
          if (values.classEndDate) {
            classEnd.date = values.classEndDate
          }
        }
        if (values.classEndType === "after") {
          classEnd.NoOfOccurence = afterOccurencesCount;
        }
      }

      if (values.classStartTime) {
        classTime.start = values.classStartTime
      }
      if (values.classEndTime) {
        classTime.end = values.classEndTime
      }
      if (values.classStartDate) {
        classTime.date = values.classStartDate
      }
      values.classRepeat = classRepeat;
      values.classEnd = classEnd;
      values.classTime = classTime;
      // console.log(values,">>>. values >>>>> ::::")
      if (images && images.length > 0) values.images = images;
      const formData = new FormData();
      for (const key in values) {
        if (Array.isArray(values[key])) {
          console.log(values[key], ">>> If  >>>")
          values[key].forEach((item: any, index: any) => {
            if (typeof item === 'object' && item instanceof File) {
              formData.append(`${key}[${index}]`, item);
            } else {
              formData.append(`${key}[${index}]`, item);
            }
          });
        }
        else if (typeof values[key] === 'object' && values[key] instanceof File) {
          console.log(values[key], ">>> Else If >>>")
          formData.append(key, values[key]);
        }
        else {
          if (typeof values[key] === 'object') {
            formData.append(key, JSON.stringify(values[key]));
          } else {
            formData.append(key, values[key]);
          }
        }
      }
      setLoading(true);
      try {
        if (sharedActions?.formDetails?._id) {
          dispatch(editClass({ id: sharedActions?.formDetails?._id, payload: formData }))
          // const result = await editClass(sharedActions?.formDetails?._id, formData)
          // if (result.data) {
          //   console.log(result.data);
          //   toast.success("Classes Updated successfully");
          //   navigate("/classes")
          // }
          dispatch(setClassesModalStatus(false));
          dispatch(setFormDetails({}));
        }
        else {
          dispatch(addClass(formData));
          dispatch(setClassesModalStatus(false));
          dispatch(setFormDetails({}));
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.responseMessage)
        setSubmitting(false);
        setLoading(false);
        dispatch(setClassesModalStatus(false));
        dispatch(setFormDetails({}));
      }
    },
  });


  return (
    <>
      <Modal
        className="addClassModal"
        backdrop="static"
        size="lg"
        show={sharedActions.classesModal}
        onHide={closeModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!sharedActions.formDetails._id ? "Add" : "Update"} Classes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <div className="classesTabWrap">
              <div className="bgFormColor p-4 mb-3">
                <label>Class Information</label>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="statusCheck d-flex justify-content-between align-items-center w-100">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <span>Class Status</span>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          {...formik.getFieldProps("classStatus")}
                          checked={formik.values.classStatus === true}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4 mb-3">
                    <div className="statusCheck d-flex justify-content-between align-items-center w-100">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <span>Cancel</span>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          {...formik.getFieldProps("canCancel")}
                          checked={formik.values.canCancel === true}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-4 mb-3">
                    <div className="statusCheck d-flex justify-content-between align-items-center w-100">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <span>Reschedule</span>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          {...formik.getFieldProps("canReschedule")}
                          checked={formik.values.canReschedule === true}
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-6 mb-3">
                    {/* <label>Service</label> */}
                    <Select
                      name="service"
                      className="custom-select-box dropdownCustom"
                      value={
                        subServices?.find(
                          (g) => g._id === formik.values?.subServiceId
                        ) || null
                      }
                      placeholder="Select Service"
                      getOptionLabel={(o: any) => o?.name}
                      getOptionValue={(o: any) => o?._id}
                      onChange={(v) => {
                        formik.setFieldValue("subServiceId", v?._id);
                      }}
                      options={subServices}
                    />
                    {/* <ErrorText show={formik.touched.subServiceId &&formik.errors.subServiceId} message={"Field is required"} /> */}
                    {formik.touched.subServiceId &&
                      formik.errors.subServiceId && (
                        <p className="errorMessage">{"Field is required"}</p>
                      )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Partner</label>
                    <Select
                      className="custom-select-box dropdownCustom"
                      value={partners?.find(
                        (g) => g._id === formik.values?.partnerId
                      )}
                      name="partnerId"
                      placeholder="Select Partner"
                      getOptionLabel={(o: any) => o?.name}
                      getOptionValue={(o: any) => o?._id}
                      onChange={(v) => {
                        formik.setFieldValue("partnerId", v?._id);
                        fetchAllLoactions(v?._id);
                      }}
                      options={partners}
                    />
                    {/* <ErrorText show = {formik.touched.subServiceId &&formik.errors.subServiceId} message={"Field is required"} /> */}
                    {formik.touched.partnerId && formik.errors.partnerId && (
                      <p className="errorMessage">{"Field is required"}</p>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Partner Location</label>
                    <Select
                      className="custom-select-box dropdownCustom"
                      value={partnerLocations?.find(
                        (g) => g._id === formik.values?.partnerLocation
                      )}
                      name="partnerLocation"
                      placeholder="Select Location"
                      getOptionLabel={(o: any) => o?.address}
                      getOptionValue={(o: any) => o?._id}
                      onChange={(v) => {
                        formik.setFieldValue("partnerLocation", v?._id);
                      }}
                      options={partnerLocations}
                    />
                    {/* <ErrorText show = {formik.touched.subServiceId &&formik.errors.subServiceId} message={"Field is required"} /> */}
                    {formik.touched.partnerLocation && formik.errors.partnerLocation && (
                      <p className="errorMessage">{"Field is required"}</p>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Class Name</label>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <div className="group-img iconLeft  position-relative textareaWrap">
                        <Form.Control
                          className="commonInput"
                          type="text"
                          placeholder="Class name"
                          {...formik.getFieldProps("className")}
                        />
                        {/* <ErrorText show={formik.touched.className &&formik.errors.className} message={"Field is required"} /> */}
                        {formik.touched.className &&
                          formik.errors.className && (
                            <p className="errorMessage">
                              {"Field is required"}
                            </p>
                          )}
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <label>Description</label>
                    <div className="group-img iconLeft  position-relative textareaWrap">
                      <label>
                        <FileIcon />
                      </label>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Tell us something about your class"
                          className="addressTextarea"
                          {...formik.getFieldProps("description")}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  {/* <div className="col-sm-6">
                    <label>Booking Instruction</label>
                    <div className="group-img iconLeft  position-relative textareaWrap">
                      <label>
                        <FileIcon />
                      </label>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Tell us something about your class"
                          className="addressTextarea"
                          {...formik.getFieldProps("bookingInstruction")}
                        />
                      </Form.Group>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="bgFormColor p-4 mb-3">
                <label>
                  <strong>Class start date</strong>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <div className="group-img iconLeft  position-relative textareaWrap">
                        <Form.Control
                          className="commonInput"
                          type="date"
                          placeholder="Class name"
                          {...formik.getFieldProps("classStartDate")}
                        />
                      </div>
                      {/* <ErrorText show={formik.touched.classStartDate &&formik.errors.classStartDate} message={"Field is required"} /> */}
                      {formik.touched.classStartDate &&
                        formik.errors.classStartDate && (
                          <p className="errorMessage">{"Field is required"}</p>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div className="wrap mt-3">
                  <label>
                    <strong>Class timings</strong>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <span>Class Start Time</span>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <div className="group-img iconLeft  position-relative textareaWrap">
                          <Form.Control
                            className="commonInput"
                            type="time"
                            placeholder="Class name"
                            {...formik.getFieldProps("classStartTime")}
                            onChange={(e) => {
                              let time = moment(e.target.value, "HH:mm").format(
                                "A"
                              );
                              setStartTimeFormat(time);
                              setStartTime(e.target.value);
                              formik.setFieldValue(
                                "classStartTime",
                                e.target.value
                              );
                            }}
                          />
                          {/* <ErrorText show={formik.touched.classStartTime &&formik.errors.classStartTime} message={"Field is required"} /> */}
                          {formik.touched.classStartTime &&
                            formik.errors.classStartTime && (
                              <p className="errorMessage">
                                {"Field is required"}
                              </p>
                            )}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <span>Class End Time</span>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <div className="group-img iconLeft  position-relative textareaWrap">
                          <Form.Control
                            className="commonInput"
                            type="time"
                            placeholder="Class name"
                            {...formik.getFieldProps("classEndTime")}
                            onChange={(e) => {
                              let time = moment(e.target.value, "HH:mm").format(
                                "A"
                              );
                              setEndTimeFormat(time);
                              setEndTime(e.target.value);
                              formik.setFieldValue(
                                "classEndTime",
                                e.target.value
                              );
                            }}
                          />
                          {/* <ErrorText show={formik.touched.classEndTime &&formik.errors.classEndTime} message={"Field is required"} /> */}
                          {formik.touched.classEndTime && formik.errors.classEndTime && (
                            <p className="errorMessage">{"Field is required"}</p>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="wrap mt-3">
                  <label>Class repeat</label>
                  <div className="row align-items-end">
                    <div className="col-lg-4">
                      <div className="statusCheck d-flex justify-content-between align-items-center w-100 position-relative mb-2">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <label className="mb-0 d-flex align-items-center">
                            <input
                              type="radio"
                              {...formik.getFieldProps("classRepeatType")}
                              checked={
                                formik.values.classRepeatType ===
                                "doesNotRepeat"
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "classRepeatType",
                                  "doesNotRepeat"
                                );
                                formik.setFieldValue(
                                  "classWeekdays",
                                  "day"
                                );
                                // formik.setFieldValue("classWeekdays", "");
                                setCount(0);
                                setSelectedDays([]);
                              }}
                            />
                            <span className="radioText">Does not repeat</span>
                            <span className="bgBlue"></span>
                          </label>
                        </div>
                      </div>
                      <div className="statusCheck d-flex justify-content-between align-items-center w-100 position-relative">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <label className="mb-0 d-flex align-items-center">
                            <input
                              type="radio"
                              {...formik.getFieldProps("classRepeatType")}
                              checked={
                                formik.values.classRepeatType === "repeat"
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "classRepeatType",
                                  "repeat"
                                );
                              }}
                            />
                            <span className="radioText">Repeat every</span>
                            <span className="bgBlue"></span>
                            <div className="addOption">
                              <input value={count} />
                              <div className="btnsWrap">
                                <button
                                  type="button"
                                  onClick={() => increment("repeat")}
                                >
                                  <AngleWhiteTopIcon />
                                </button>
                                <button
                                  type="button"
                                  className="rotate"
                                  onClick={() => decrement("repeat")}
                                >
                                  <AngleWhiteTopIcon />
                                </button>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8">
                      {formik.values.classRepeatType !== "doesNotRepeat" && (
                        <ul className="daysRadioBox">
                          {slotOptions.map((option) => (
                            <li key={option}>
                              <label>
                                <input
                                  type="radio"
                                  name="classWeekdays"
                                  value={option}
                                  // onChange={formik.handleChange}
                                  onChange={(e) => {
                                    setSelectedDays(["everyday"]);
                                    formik.setFieldValue(
                                      "classWeekdays",
                                      e.target.value
                                    );
                                  }}
                                  checked={
                                    formik.values.classWeekdays === option
                                  }
                                />
                                <span className="day">{option}</span>
                                <span className="bg"></span>
                              </label>
                            </li>
                          ))}
                          {formik.touched.classWeekdays && formik.errors.classWeekdays && (
                            <p className="errorMessage mt-2">{"Field is required"}</p>
                          )}
                        </ul>
                      )}
                    </div>
                    {/* <ErrorText show={formik.touched.classRepeatType && formik.errors.classRepeatType} message={"Class repeat is required field"} /> */}
                    {formik.touched.classRepeatType && formik.errors.classRepeatType && (
                      <p className="errorMessage">{"Field is required"}</p>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      {formik?.values?.classWeekdays == "week" && (
                        <ul className="daysCheckbox">
                          {daysOfWeek.map((day) => (
                            <li key={day.id}>
                              <label>
                                <input
                                  type="checkbox"
                                  value={day.id}
                                  checked={selectedDays.includes(day.id)}
                                  onChange={handleDayCheckboxChange}
                                />
                                <span className="day">{day.name}</span>
                                <span className="bg"></span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div className="wrap mt-3">
                  <label>Class ends</label>
                  <div className="row">
                    {/* <div className="col-xl-4">
                  <div className="classEnd">
                    <div className="statusCheck d-flex justify-content-between align-items-center w-100 position-relative mb-2">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <label className="mb-0 d-flex align-items-center">
                          <input
                            type="radio"
                            name="classEndType"
                            onChange={(e) => {
                              formik.setFieldValue("classEndType", "never");
                              formik.setFieldValue("classEndDate", "");
                              setAfterOccurencesCount(0);
                            }}
                            checked={formik.values.classEndType === "never"}
                          />
                          <span className="radioText">Never</span>
                          <span className="bgBlue"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                    <div className="col-md-6">
                      <div className="classEnd">
                        <div className="statusCheck d-flex justify-content-between align-items-center w-100 position-relative mb-2">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label className="mb-0 d-flex align-items-center">
                              <input
                                type="radio"
                                name="classEndType"
                                onChange={(e) => {
                                  formik.setFieldValue("classEndType", "on");
                                }}
                                checked={formik.values.classEndType === "on"}
                              />
                              <span className="radioText">On</span>
                              <span className="bgBlue"></span>
                            </label>
                            <div className="dateShow">
                              <Form.Control
                                className="commonInput"
                                type="date"
                                placeholder="Class name"
                                {...formik.getFieldProps("classEndDate")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="classEnd">
                        <div className="statusCheck d-flex justify-content-between align-items-center w-100 position-relative mb-2">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label className="mb-0 d-flex align-items-center">
                              <input
                                type="radio"
                                name="classEndType"
                                onChange={(e) => {
                                  formik.setFieldValue("classEndType", "after");
                                }}
                                checked={formik.values.classEndType === "after"}
                              />
                              <span className="radioText">After</span>
                              <span className="bgBlue"></span>
                              <div className="addOccurenceOption">
                                <input
                                  value={afterOccurencesCount + " occurences"}
                                />
                                <div className="btnsWrap">
                                  <button
                                    type="button"
                                    onClick={() => increment("end")}
                                  >
                                    <AngleWhiteTopIcon />
                                  </button>
                                  <button
                                    type="button"
                                    className="rotate"
                                    onClick={() => decrement("end")}
                                  >
                                    <AngleWhiteTopIcon />
                                  </button>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <ErrorText show={formik.touched.classEndType && formik.errors.classEndType} message={"Class Ends is required field"} /> */}
                    {formik.touched.classEndType && formik.errors.classEndType && (
                      <p className="errorMessage">{"Field is required"}</p>
                    )}
                  </div>
                  <ul className="addedTime my-3">
                    {(selectedDays.length > 0 || startTime !== "") && (
                      <li>
                        <TimerIcon />
                        <label>
                          <label>
                            {selectedDays?.join(", ") +
                              (selectedDays.length > 0 ? " |" : "")}{" "}
                            {startTime} {startTimeFormat}{" "}
                            {startTime !== "" && endTime !== "" ? " - " : ""}
                            {endTime} {endTimeFormat}
                          </label>
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="bgFormColor p-4 mb-3">
                <label>Class capacity</label>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <div className="group-img iconLeft rightLeft  position-relative textareaWrap">
                        <label className="pt-3 icon-posoition">
                          <GroupUsersIcon />
                        </label>
                        <Form.Control
                          className="commonInput"
                          type="text"
                          {...formik.getFieldProps("maxBooking")}
                        />
                        <span className="person">| person</span>
                      </div>
                      {formik.touched.maxBooking && formik.errors.maxBooking && (
                        <p className="errorMessage">{"Field is required"}</p>
                      )}
                      {/* <ErrorText show={formik.touched.maxBooking &&formik.errors.maxBooking} message={"Class Ends is required field"} /> */}
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="bgFormColor p-4 mb-3">
                <label>Class images</label>
                <div className="uploadWrapper">
                  <ul className="outerBlock">
                    <li>
                      <ul className="showImages">
                        {previews.map(({ preview, type }: any, index: any) => (
                          <li className="position-relative" key={index}>
                            <button
                              type="button"
                              className="crossBtn removeGymFile"
                              onClick={() => handleRemoveImage(index)}
                            >
                              <CrossWhiteBlackIcon />
                            </button>
                            <div className="image">
                              {type.startsWith("video") ? (
                                <video
                                  src={
                                    isLiveUrl(preview)
                                      ? fileUrl + preview
                                      : preview
                                  }
                                  controls
                                  className="w-100"
                                />
                              ) : (
                                <img
                                  src={
                                    isLiveUrl(preview)
                                      ? fileUrl + preview
                                      : preview
                                  }
                                  alt={`Preview ${index}`}
                                  className="w-100"
                                />
                              )}
                            </div>
                          </li>
                        ))}
                        <li className="uploadBlock">
                          <div className="upload text-center">
                            <input
                              type="file"
                              multiple
                              onChange={handleFileChange}
                              accept="image/*,video/*"
                            />
                            <img
                              src={"/media/products/uploadIcon.png"}
                              alt="uploadIcon"
                            />
                            <p>Drop or upload images</p>
                            <button>Browse image</button>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start">
                <button type="submit" className="saveBtn">
                  Save class
                </button>
                {/* <Link className="policyLink" to={"#"}>
              Read about our visitors class cancellation policy
            </Link> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { ClassesModal };
