/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../../../redux/features/notification/_notificationAction';
import { KTCard } from '../../../../_metronic/helpers';
import { CommonTable } from '../common/common-list/table/Table';
import { Pagination } from '../common/common-list/components/pagination/Pagination';
import { setId } from '../../../../redux/features/shared/sharedSlice';
import { notificationColumns } from '../common/common-list/table/columns/_notificationColumns';

const Notifications = () => {
    const dispatch: any = useDispatch();

    const { data: notificationList = [], totalRecord } = useSelector(
        (state: any) => state.notificationList
    );

    useEffect(() => {
        dispatch(setId('Notification'))
        dispatch(getNotifications({ page: 1, limit: 10 }));
    }, [dispatch]);

    const handleClick = (page: number) => {
        dispatch(getNotifications({ page: page, limit: 10 }));
    };


    return (
        <>
            <KTCard>
                <Container className="my-5">
                    <Row className="mb-4">
                        <Col className="d-flex justify-content-between">
                            <h1>Notification List</h1>
                        </Col>
                    </Row>
                </Container>

                <CommonTable data={notificationList} columns={notificationColumns} />
                {totalRecord > 10 && <Pagination
                    totalRecord={totalRecord}
                    handleClick={handleClick}
                />}
            </KTCard>
        </>
    );

};

export default Notifications;