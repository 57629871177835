// FAQForm.js
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, FormikProvider } from 'formik';
import * as Yup from 'yup';
import FieldInputTextarea from '../common/InputFeilds/InputTextareaField';
import SubmitButton from '../common/common-list/components/submitButton/submitButton';

const FAQForm = ({ showModal, handleClose, onSubmit, initialValues, title, loading }) => {

  const faqValidationSchema = Yup.object({
    question: Yup.string()
      .required('Question is required')
      .min(10, 'Question must be at least 10 characters long')
      .max(100, 'Question must be less than or equal to 100 characters'),
    answer: Yup.string()
      .required('Answer is required')
      .min(20, 'Answer must be at least 20 characters long')
      .max(500, 'Answer must be less than or equal to 500 characters')
  });


  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title !== "Add" ? 'Upadte FAQ' : 'Add FAQ'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={faqValidationSchema}
          onSubmit={onSubmit}
        >
          {formik => (
            <FormikProvider value={formik}>
              <FormikForm>
                <Form.Group controlId="question">
                  <Field
                    name="question"
                    component={FieldInputTextarea}
                    label="Question"
                    isRequired={true}
                    placeholder="Enter the question"
                    maxLength={100}
                    as="textarea"
                  />
                </Form.Group>

                <Form.Group controlId="answer" className="mt-3">
                  <Field
                    name="answer"
                    component={FieldInputTextarea}
                    label="Answer"
                    isRequired={true}
                    placeholder="Enter the answer"
                    maxLength={500}
                    rows={5}
                    as="textarea"
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                 
                  <SubmitButton  type = "submit" loading = {loading} title = {title !== "Add" ? 'Save Changes' : 'Add FAQ'}/>
                </Modal.Footer>
              </FormikForm>
            </FormikProvider>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default FAQForm;
