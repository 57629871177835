/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormDetails,
  setPlansModalStatus
} from "../../../../redux/features/shared/sharedSlice";
import { Field, FormikProvider, useFormik } from "formik";
import { Form } from "react-bootstrap";
import FieldInputText from "../common/InputFeilds/InputTextField";
import * as Yup from "yup";
import {
  REQUIRED,
} from "../../../../utils/const";
import { useEffect, useState } from "react";
import { LANG } from "../../../constants/language";
import Select from "react-select"
import { addPlan, updatePlan } from "../../../../redux/features/plans/_plansAction";
function PlansModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);
  const interval: any =   [{ value: "monthly", label: "monthly" }];
  const [imageUrl, setImageUrl] = useState<any>();
  const FormValidation = Yup.object().shape({
    name: Yup.string().required(REQUIRED),
    interval: Yup.string(),
    price: Yup.number().required(REQUIRED),
  });

  const formIntialValues = {
    name: sharedActions?.formDetails?.name || "",
    interval: "monthly",
    price: sharedActions?.formDetails?.price || ""
  };

  const plansFormik = useFormik({
    initialValues: formIntialValues,
    validationSchema: FormValidation,
    onSubmit: (values: any, { resetForm }) => {
      console.log(values,">>> values >>>")
      if (sharedActions?.formDetails?._id) {
        dispatch(
          updatePlan({
            ...values,
            id: sharedActions.formDetails?._id,
          })
        );
      } else {
        dispatch(
          addPlan({ ...values})
        );
      }
      dispatch(setFormDetails({}));
      setTimeout(() => {
        dispatch(setPlansModalStatus(false));
        resetForm();
      }, 500);
    },
    enableReinitialize: true,
  });

  useEffect(() => {

  }, [])

  const closeModal = () => {
    dispatch(setPlansModalStatus(false));
    dispatch(setFormDetails({}));
  };

  return (
    <>
      <Modal
        backdrop="static"
        size="lg"
        show={sharedActions.plansModal}
        onHide={closeModal}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!sharedActions.formDetails?._id ? "Add" : "Update"} Plans
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={plansFormik}>
            <Form onSubmit={plansFormik.handleSubmit} method="POST">
              <div className="row">
                <div className="col-sm-12  col-md-4 mb-6">
                  <Form.Group>
                    <Field
                      name="name"
                      validate={FormValidation}
                      type="text"
                      label={LANG.NAME}
                      isRequired={true}
                      component={FieldInputText}
                      placeholder="Name"
                      maxLength={30}
                    />
                  </Form.Group>
                </div>
                {/* <div className="col-sm-12  col-md-4 mb-6">
                  <Form.Group>
                    <Form.Label>
                      Interval <span>*</span>
                    </Form.Label>
                    <Select
                      className="custom-select-box"
                      value={interval.find(
                        (g) => g.value === plansFormik.values?.interval
                      )}
                      name="interval"
                      placeholder="Interval"
                      onChange={(v) => {
                        plansFormik.setFieldValue("interval", v?.value);
                      }}
                      isDisabled={true}
                      options={interval}
                    />

                  </Form.Group>
                  {plansFormik.errors.interval &&
                    plansFormik.touched.interval && (
                      <div className="formik-errors text-danger">{`${plansFormik.errors.interval}`}</div>
                    )}
                </div> */}
                <div className="col-sm-12  col-md-12 mb-6">
                  <Form.Group>
                    <Field
                      name="price"
                      validate={FormValidation}
                      type="number"
                      min={0}
                      isRequired={true}
                      label="Price"
                      component={FieldInputText}
                    />
                  </Form.Group>
                </div>
                {/* <div className="col-sm-12  col-md-12 mb-6">
                  <Form.Group>
                    <Field
                      name="duration_months"
                      validate={FormValidation}
                      type="number"
                      min={0}
                      isRequired={true}
                      label="Duration month"
                      component={FieldInputText}
                    />
                  </Form.Group>
                </div> */}
              </div>

              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  className="primaryBtn"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit" className="primaryBtn active">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { PlansModal };
