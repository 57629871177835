// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'

const servicesColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Name' className='min-w-50px' />
    ),
    id: 'name',
    Cell: ({ ...props }) => {
      const serviceName = props.data[props.row.index]?.name || "";
      const capitalize = (name: string) =>
        name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
      const formattedName = `${serviceName}`;
      // return <TitleCell userObj={formattedName} />;
      return (
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <span className="text-gray-800 mb-1">
              {formattedName ? formattedName : "--"}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Description' className='min-w-50px' />,
    id: 'description',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.description} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  },
]

export { servicesColumns }
