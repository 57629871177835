import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { SubscriptionList } from "./SubscriptionList";

const companyBreadcrumbs: Array<PageLink> = [
  {
    title: "Subscripiton Management",
    path: "/subscriptions/list",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const SubscriptionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={companyBreadcrumbs}>Subscription list</PageTitle>
              <SubscriptionList/>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/subscriptions/list" />} />
    </Routes>
  );
};

export default SubscriptionPage;
