import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
const API_URL = process.env.REACT_APP_API_URL;
const GET_Plans = `${API_URL}/adminWeb/plans`
const DELETE_PLAN = `${API_URL}/adminWeb/plan/`
const ADD_PLAN = `${API_URL}/adminWeb/plan`
const UPDATE_PLAN = `${API_URL}/adminWeb/plan/`
export const getPlans = createAsyncThunk(
  "getPlans",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_Plans}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const updatePlan = createAsyncThunk(
  "updatePlan",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put(`${UPDATE_PLAN}${values.id}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getPlans({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const addPlan = createAsyncThunk(
  "addPlan",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${ADD_PLAN}`, values);
      notify(data.responseMessage, 'success');
      dispatch(getPlans({ page: values.selectedPage || 1, limit: 10 }))
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);


export const deletePlan = createAsyncThunk(
  "deletePlan",
  async (values: any, { rejectWithValue, dispatch }) => { 
    try {
      const { data } = await axios.delete(`${DELETE_PLAN}${values.id}`);
      notify(data.responseMessage, 'success'); 
      dispatch(getPlans({page: values.selectedPage || 1, limit:10}));
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);



