// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import { StatusCell } from './StatusCell'

const employeeColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Name' className='min-w-50px' />
    ),
    id: 'name',
    Cell: ({ ...props }) => {
      const capitalize = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
      const firstName = capitalize(props.data[props.row.index]?.firstName);
      const lastName = capitalize(props.data[props.row.index]?.lastName);
    
      return <TitleCell userObj={`${firstName} ${lastName}`} />;
    },    
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Company Name' className='min-w-50px' />,
    id: 'companyName',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.companyName } />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Package Name' className='min-w-50px' />,
    id: 'packageName',
    Cell: ({ ...props }) => {
      const capitalize = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
      const packageName = capitalize(props.data[props.row.index]?.packageName);
    
      return <TitleCell userObj={packageName} />;
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-50px' />,
    id: 'status',
    Cell: ({ ...props }) => <StatusCell userObj={props.data[props?.row?.index]} from="employee"/>,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Registered Date' className='min-w-50px' />,
    id: 'createdAt',
    Cell: ({ ...props }) => <TitleCell userObj={new Date(props.data[props.row.index]?.createdAt).toLocaleDateString()} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  },
]

export { employeeColumns }
