import "./style.scss";
import { Slide, toast } from "react-toastify";
import Swal from "sweetalert2";
import { getPartners } from "../redux/features/partner/_partnerAction";
import { getUsers } from "../redux/features/user/_userAction";
import { getEvents } from "../redux/features/event/_eventAction";
import { getServices } from "../redux/features/service/_serviceAction";
import { getSubServices } from "../redux/features/subServices/_subServicesAction";
import { getWellnessTypes } from "../redux/features/wellnessTypes/_wellnessTypesAction";
import { getCompanies } from "../redux/features/company/_companyAction";
import { getNotifications } from "../redux/features/notification/_notificationAction";
import { getDashboardData } from "../redux/features/dashboard/_dashboardAction";
import { getSubscriptions } from "../redux/features/subscription/_subscriptionAction";
import { getEmployees } from "../redux/features/employee/_employeeAction";
import { getSubscribedAccounts } from "../redux/features/subscribedAccounts/_subscribedAccountsAction";
import { getPartnerAnalytics } from "../redux/features/partnerAnalytics/_partnerAnalyticsAction";
import { getBookings } from "../redux/features/bookings/_bookingsAction";
import { getClasses } from "../redux/features/classes/_classesAction";
import { getCustomReport } from "../redux/features/financialReport/_financialReportActions";

export const commonSwtichCases = (id: any, searchValue: any, dispatch: any) => {
    searchValue = encodeURIComponent(searchValue)
    console.log('id >>>> ', id);

    switch (id) {
        case 'Partner':
            dispatch(getPartners({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        case 'User':
            dispatch(getUsers({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        case 'Event':
            dispatch(getEvents({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        case "subServices":
            dispatch(getSubServices({ search: searchValue.trim(), page: 1, limit: 10 }))
            break;
        case 'Service':
            dispatch(getServices({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case "WellnessTypes":
            dispatch(getWellnessTypes({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case "Company":
            dispatch(getCompanies({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case 'Notification':
            dispatch(getNotifications({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case 'Dashboard':
            dispatch(getDashboardData({ search: searchValue.trim() }));
            break;
        case 'Subscription':
            dispatch(getSubscriptions({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case 'Employee':
            dispatch(getEmployees({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case 'SubscribedAccounts':
            dispatch(getSubscribedAccounts({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case 'PartnerAnalytics':
            dispatch(getPartnerAnalytics({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case 'Bookings':
            dispatch(getBookings({ search: searchValue.trim(), page: 1, limit: 10 }));
            break;
        case 'Classes':
            dispatch(getClasses({ page: 1, limit: 10, search: searchValue.trim(), }));
            break;
        default:
    }
}

export const conFirmMessage = (values) => {
    return Swal.fire(values)
}

export const commonUserStatusSwtichCases = (id: any, values: any, dispatch: any) => {
    switch (id) {
        case 'Speciality':
            // dispatch(getSpeciality({ search: searchValue.trim() }))
            break;
        case 'Category':
            // dispatch(getCategory({ search: searchValue.trim() }))
            break;
        case 'USER':
            dispatch(getUsers({ page: 1, limit: 10 }))
            break;
        default:
    }
}

export const docUrl = (images: any) => {
    const urlArray: any = [];
    for (let i = 0; i < images.length; i++) {
        urlArray.push(images[i].url);
    }
    return urlArray
}

export const toastifyOptions: any = {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Slide,
};

export const notify = (message: any, type: any) => {
    if (type === 'error') {
        return toast.error(message, toastifyOptions);
    } else if (type === 'info') {
        return toast.info(message, toastifyOptions);
    } else if (type === 'warning') {
        return toast.warning(message, toastifyOptions);
    } else if (type === 'success') {
        return toast.success(message, toastifyOptions);
    }
};

export const successMessage = (message: string) => {
    toast.success(message);
}

export const errorMessage = (message: string) => {
    toast.error(message)
}

export const commonYearFilterCases = (id: any, year: any, dispatch: any) => {
    switch (id) {

        case 'PartnerAnalytics':
            dispatch(getPartnerAnalytics({ page: 1, limit: 10, year }));
            break;

        default:
    }
}


export const commonDateRangeFilterCases = (id: any, startDate: any, endDate: any, dispatch: any) => {
    switch (id) {

        case 'SubscribedAccounts':
            dispatch(getSubscribedAccounts({ page: 1, limit: 10, startDate, endDate }));
            break;

        case 'PartnerAnalytics':
            dispatch(getPartnerAnalytics({ page: 1, limit: 10, startDate, endDate }));
            break;

        case 'Bookings':
            dispatch(getBookings({ page: 1, limit: 10, startDate, endDate }));
            break;

        case 'CustomReport':
            dispatch(getCustomReport({ startDate, endDate }));
            break;

        default:
    }
}