/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify, successMessage } from "../../../utils/shared";
import { setCloseCreateTermModal, setCloseDeleteTermModal, setNewlyAddedTerm, updateTerm } from "./termSlice";


const API_URL = process.env.REACT_APP_API_URL;
const TermsUrl = `${API_URL}/term`;


export const TermsList = createAsyncThunk(
    "TermsList",
    async (_void, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.get(`${TermsUrl}/termsList`);
        return data;
      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        
        return rejectWithValue(error.message);
      }
    }
  );

  export const CreateTerm = createAsyncThunk(
    "CreateTerm",
    async (values: any, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.post(`${TermsUrl}/createTerm`,values);
        dispatch(setCloseCreateTermModal());
        dispatch(setNewlyAddedTerm(data.data));
        successMessage(data.responseMessage);

        return data;

      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        // notify(responseMessage, 'error');
        return rejectWithValue(error.message);
      }
    }
  );

  export const UpdateTerm = createAsyncThunk(
    "UpdateTerm",
    async (item: any, { rejectWithValue, dispatch }) => {
      try {
        
        const { data } = await axios.put(`${TermsUrl}/updateTerm/${item.term._id}`,item.values);
        dispatch(setCloseCreateTermModal());
        dispatch(updateTerm(data.data));
        successMessage(data.responseMessage);

        return data;
      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        // notify(responseMessage, 'error');
        return rejectWithValue(error.message);
      }
    }
  );


  export const DeleteTerm = createAsyncThunk(
    "DeleteTerm",
    async (id: string, { rejectWithValue, dispatch }) => {
      try {
        const { data } = await axios.delete(`${TermsUrl}/deleteTerm/${id}`);
        dispatch(TermsList());
         successMessage(data.responseMessage);
         dispatch(setCloseDeleteTermModal());
        return data;
      } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        // notify(responseMessage, 'error');
        return rejectWithValue(error.message);
      }
    }
  );



