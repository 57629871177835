/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { COMPANY_STATUS, SUBSCRIPTION_STATUS } from "../../../../../../../utils/const";
import { useDispatch, useSelector } from "react-redux";
import { updateCompany } from "../../../../../../../redux/features/company/_companyAction";
import { updateSubscription } from "../../../../../../../redux/features/subscription/_subscriptionAction";
import { updateEmployee } from "../../../../../../../redux/features/employee/_employeeAction";
type Props = {
  userObj: any;
  from: any;
};

const StatusCell: FC<Props> = ({ userObj, from }) => {
  const dispatch: any = useDispatch();
  let options = COMPANY_STATUS;
  if(from === "subscription" || from === "employee"){
    options = SUBSCRIPTION_STATUS;
  }
  const sharedActions: any = useSelector((state: any) => state.sharedActions);
  return (
    <>
      <Form.Group>
        <Select
          className="custom-select-box"
          value={options.find((g) => g.value === userObj.status)}
          name="status"
          placeholder="Select status"
          onChange={(v) => {
            console.log(v.value, ">>> value >>>>>");
            let values: any = {status: v?.value}
            if(from === "subscription"){
              dispatch(
                updateSubscription({
                  ...values,
                  id: userObj?._id,
                }))
            }
            else if(from === "employee") {
              dispatch(
                updateEmployee({
                  ...values,
                  id: userObj?._id,
                }))
            }
            else {
              dispatch(
                updateCompany({
                  ...values,
                  id: userObj?._id,
                })
            )
            }
          }}
          options={options}
        />
      </Form.Group>
    </>
  );
};

export { StatusCell };
