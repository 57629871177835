/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { Tab, Tabs, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setSelectedReportTab } from "../../../../redux/features/financialReport/financialReportSlice";
import { setId } from "../../../../redux/features/shared/sharedSlice";
import { getCustomReport } from "../../../../redux/features/financialReport/_financialReportActions";
import EngagementReport from "./engagementReport";
import FinancialReport from "./financialReport";
import { UserListHeader } from "../common/common-list/components/header/UserListHeader";
import { format } from "date-fns";

const CustomReport = () => {
  const dispatch: any = useDispatch();
  const selectedTab = useSelector((state: any) => state.financialReport.selectedTab);
  const location: any = useLocation();
  const activeTabFromLocation = location?.state?.activeTab || "financialReport";
  const reportData = useSelector((state: any) => state?.financialReport?.customReports);

  // Calculate default date range (past 1 month)
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);
  // Format the dates before dispatching
  const formattedStart = oneMonthAgo ? format(oneMonthAgo, "yyyy-MM-dd") : null;
  const formattedEnd = today ? format(today, "yyyy-MM-dd") : null;

  useEffect(() => {
    dispatch(setId('CustomReport'))
    dispatch(getCustomReport({ startDate: formattedStart, endDate: formattedEnd  }));

    if (selectedTab !== activeTabFromLocation) {
      dispatch(setSelectedReportTab(activeTabFromLocation));
    }
  }, [activeTabFromLocation, dispatch, selectedTab]);

  const setSelectedTab: any = (tab: string) => dispatch(setSelectedReportTab(tab));

  const tabs = [
    { eventKey: "financialReport", title: "Financial Report" },
    { eventKey: "engagementReport", title: "Engagement Report" },
  ];


  return (

    <Container className="mt-4">

      <div className="d-flex justify-content-between align-items-center">
        <h1>Custom Report</h1>
        <UserListHeader />
      </div>

      <Tabs id="reports-tabs" activeKey={selectedTab} onSelect={setSelectedTab} className="mb-3">

        <Tab eventKey="financialReport" title="Financial Report">
          <FinancialReport data={reportData?.financialReport} />
        </Tab>

        <Tab eventKey="engagementReport" title="Engagement Report">
          <EngagementReport data={reportData?.engagementReport} />
        </Tab>

      </Tabs>
    </Container>

  );
};

export default CustomReport;
