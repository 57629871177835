
import { createSlice } from "@reduxjs/toolkit";
import { GetSubscriptions } from "./_paymentAction";

const initialState: any = {
    subscriptions: [],
    totalRecord: 0,
    loading: false,
    openCreateSubscriptionPlanModal: false,
    selectedSubscription: null,
    // openDeleteTermModal:false
};

export const subscriptionActions = createSlice({
    name: "subscriptionActions",
    initialState: initialState,
    reducers: {
    

        setOpenCreateSubscriptionPlanModal: (state) => {
            state.openCreateSubscriptionPlanModal = true
        },
        setCloseCreateSubscriptionPlanModal: (state) => {
            state.openCreateSubscriptionPlanModal = false
        },
        
        // setOpenDeleteTermModal: (state) => {
        //     state.openDeleteTermModal = true
        // },
        // setCloseDeleteTermModal: (state) => {
        //     state.openDeleteTermModal = false
        // },

        setSelectedSubscription: (state,{payload}) => {
            state.selectedSubscription = payload;
        }
    }
});

export const getSubscriptions = createSlice({
    name: "getSubscriptions",
    initialState: initialState,
    reducers: {
       
        setNewlyAddedTerm: (state, { payload }) => {
            state.subscriptions = [...state.subscriptions, payload]
        },

        updateTerm: (state, { payload }) => {
            let data = payload

            console.log(payload,">>> payload >>>>> updateTerm")
            let subscriptions = [...state.subscriptions];
            let index =  subscriptions.findIndex((e:any)=> e._id === data._id);
            if(index !== -1) subscriptions[index] = data;
            state.subscriptions = subscriptions;
            state.totalRecord = payload?.totalRecord;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(GetSubscriptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetSubscriptions.fulfilled, (state, { payload }) => {

                console.log(payload,">>> payload >>>>>")
                state.loading = false;
                state.subscriptions = payload?.data || [];
                state.totalRecord = payload?.totalRecord;
            })
            .addCase(GetSubscriptions.rejected, (state, { payload }) => {
                state.loading = false;

            })
    },
});


// export const createTerm = createSlice({
//     name: "createTerm",
//     initialState: initialState,
//     reducers: {},
//     extraReducers(builder) {
//         builder
//             .addCase(CreateTerm.pending, (state) => {
//                 state.loading = true;
//             })
//             .addCase(CreateTerm.fulfilled, (state, { payload }) => {
//                 state.loading = false;
//             })
//             .addCase(CreateTerm.rejected, (state, { payload }) => {
//                 state.loading = false;

//             })
//     },
// });

export const {setCloseCreateSubscriptionPlanModal,setOpenCreateSubscriptionPlanModal ,setSelectedSubscription} = subscriptionActions.actions;

export const {setNewlyAddedTerm,updateTerm}  = getSubscriptions.actions