/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import EventPage from "../modules/apps/events/EventPage";
import ServicePage from "../modules/apps/services/ServicePage";
import WellnessTypesPage from "../modules/apps/wellnessTypes/WellnessTypesPage";
import SubServicesPage from "../modules/apps/subServices/SubServicesPage";
import CompanyPage from "../modules/apps/company/CompanyPage";
import PartnerPage from "../modules/apps/partners/PartnerPage";
import SubscriptionPage from "../modules/apps/subscription/SubscripionPage";
import PlansPage from "../modules/apps/plans/PlansPage";
import EmployeePage from "../modules/apps/employees/EmployeePage";
import SettingPage from "../modules/apps/Settings/SettingPage";
import SubscriptionByPaymobPage from "../modules/apps/subscriptionByPaymob/subscriptionByPaymobPage";
import DashboardPage from "../modules/apps/dashboard/DashboardPage";
import SubscribedAccountPage from "../modules/apps/subscribedAccounts/subscribedAccountPage";
import PartnerAnalyticPage from "../modules/apps/partnerAnalytics/partnerAnalyticPage";
import ClassesPage from "../modules/apps/classes/ClassesPage";
import BookingPage from "../modules/apps/bookings/bookingPage";
import CustomReportPage from "../modules/apps/customReport/customReportPage";

const PrivateRoutes = () => {
  const UserPage = lazy(() => import("../modules/apps/users/UserPage"));
  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="auth/*" element={<Navigate to="/users" />} />

          <Route
            path="dashboard/*"
            element={
              <SuspensedView>
                <DashboardPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />

          <Route
            path="wellnessTypes/*"
            element={
              <SuspensedView>
                <WellnessTypesPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/wellnessTypes/list" />} />

          <Route
            path="services/*"
            element={
              <SuspensedView>
                <ServicePage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/services/list" />} />

          <Route
            path="subServices/*"
            element={
              <SuspensedView>
                <SubServicesPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/subServices/list" />} />

          <Route
            path="events/*"
            element={
              <SuspensedView>
                <EventPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/events/list" />} />


          <Route
            path="companies/*"
            element={
              <SuspensedView>
                <CompanyPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/companies/list" />} />

          <Route
            path="partners/*"
            element={
              <SuspensedView>
                <PartnerPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/partners/list" />} />

          <Route
            path="classes/*"
            element={
              <SuspensedView>
                <ClassesPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/classes/list" />} />

          {/* <Route
            path="subscriptions/*"
            element={
              <SuspensedView>
                <SubscriptionPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/subscriptions/list" />} />   */}

          <Route
            path="employees/*"
            element={
              <SuspensedView>
                <EmployeePage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/employees/list" />} />

          <Route
            path="plans/*"
            element={
              <SuspensedView>
                <PlansPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/plans/list" />} />

          <Route
            path="subscriptions/*"
            element={
              <SuspensedView>
                <SubscriptionPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/subscriptions/list" />} />

          <Route
            path="subscribedAccounts/*"
            element={
              <SuspensedView>
                <SubscribedAccountPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/subscribedAccounts/list" />} />

          <Route
            path="partnerAnalytics/*"
            element={
              <SuspensedView>
                <PartnerAnalyticPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/partnerAnalytics/list" />} />

          <Route
            path="bookings/*"
            element={
              <SuspensedView>
                <BookingPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/bookings/list" />} />

          <Route
            path="customReport/*"
            element={
              <SuspensedView>
                <CustomReportPage />
              </SuspensedView>
            }
          />

          <Route
            path="settings/*"
            element={
              <SuspensedView>
                <SettingPage />
              </SuspensedView>
            }
          />
          {/* <Route path="*" element={<Navigate to="/settings" />} /> */}
        </Route>
      </Routes>
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
