// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { ImageCell } from './ImageCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'

const wellnessTypesColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Image' className='min-75px' />,
  //   id: 'image',
  //   Cell: ({ ...props }) => <ImageCell image={props.data[props.row.index]?.image} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-50px' />,
    id: 'name',
    // Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].name} />,
    Cell: ({ ...props }) => {
      const name = props.data[props.row.index]?.name || '';
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      return <TitleCell userObj={formattedName} />;
    },
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Description' className='min-w-50px' />,
    id: 'description',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index].description} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-75px' />
    ),
    id: 'action',
    Cell: ({ ...props }) => <ActionCell user={props.data[props.row.index]} />,
  }
]

export { wellnessTypesColumns }
