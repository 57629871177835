import EventIcon from "../../../../app/icons/EventIcon";
import PartnersIcon from "../../../../app/icons/PartnersIcon";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to="/dashboard" icon={<EventIcon />} title="Dashboard" />

      <AsideMenuItem to="/wellnessTypes" icon={<EventIcon />} title="Business Category" />

      <AsideMenuItem to="/services" icon={<EventIcon/>} title="Services" />
      
      <AsideMenuItem to="/subServices" icon={<EventIcon />} title="Sub Services" />
      
      <AsideMenuItem to="/partners" icon={<EventIcon />} title="Partners" />

      <AsideMenuItem to="/classes" icon={<EventIcon />} title="Classes" />

      <AsideMenuItem to="/companies" icon={<EventIcon />} title="Companies" />

      <AsideMenuItem to="/employees" icon={<EventIcon />} title="Employees" />

      <AsideMenuItem to="/plans" icon={<EventIcon />} title="Subscription Packages" />

      <AsideMenuItem to="/subscriptions" icon={<EventIcon />} title="Assign Subscription Packages" />

      <AsideMenuItem to="/subscribedAccounts" icon={<EventIcon />} title="Employees & Subscriptions" />

      <AsideMenuItem to="/partnerAnalytics" icon={<EventIcon />} title="Partner Analytics" />

      <AsideMenuItem to="/bookings" icon={<EventIcon />} title="Bookings" />

      <AsideMenuItem to="/customReport" icon={<EventIcon />} title="Custom Report" />

      <AsideMenuItem to="/settings" icon={<EventIcon />} title="Settings" />


      {window.location.pathname === '/user/change-password' && <AsideMenuItem to="/user" icon={<PartnersIcon/>} title="Change Password" />}
    </>
  );
}
