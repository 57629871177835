import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { ClassesList } from "./ClassesList";

const classesBreadcrumbs: Array<PageLink> = [
  {
    title: "Classes Management",
    path: "/classes/list",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ClassesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadcrumbs={classesBreadcrumbs}>Classes list</PageTitle>
              <ClassesList/>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/classes/list" />} />
    </Routes>
  );
};

export default ClassesPage;
