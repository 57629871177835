import "./style.scss";
import { notify } from "./shared";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_ALL_SUBSCRIBED_ACCOUNT = `${API_URL}/adminWeb/subscribedAccountList`;
const GET_PARTNER_ANALYTICS = `${API_URL}/adminWeb/partnerAnalytics`;
const GET_BOOKING_ANALYTICS = `${API_URL}/adminWeb/bookingAnalytics`;



export const getEmployeeCsvData = async (values: any) => {
    try {
        const { page, limit, search = '', sortOrder = 'date', startDate, endDate, download = "false" } = values
        const { data } = await axios.get(`${GET_ALL_SUBSCRIBED_ACCOUNT}?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}&download=${download}`, {});
        return data;
    } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        notify(responseMessage, 'error');
        return null
    }
};

export const getPartnerAnalyticsCsvData = async (values: any) => {
    try {
        const { page, limit, search = '', sortOrder = 'date', startDate, endDate, download = "false" } = values
        const { data } = await axios.get(`${GET_PARTNER_ANALYTICS}?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}&download=${download}`, {});
        return data;
    } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        notify(responseMessage, 'error');
        return null
    }
};

export const getBookingsCsvData = async (values: any) => {
    try {
        const { page, limit, search = '', sortOrder = 'date', startDate, endDate, download = "false" } = values
        const { data } = await axios.get(`${GET_BOOKING_ANALYTICS}?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}&download=${download}`, {});
        return data;
    } catch (error: any) {
        const { responseMessage } = error?.response?.data;
        notify(responseMessage, 'error');
        return null
    }
};


