// @ts-nocheck
import { Column } from 'react-table'
import { TitleCell } from './TitleCell'
import { UserCustomHeader } from './CustomHeader'
import { ActionCell } from './ActionCell'
import { StatusCell } from './StatusCell'

let monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const bookingsColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Month' className='min-w-50px' />
    ),
    id: 'month',
    Cell: ({ ...props }) => <TitleCell userObj={monthArray[parseInt(props.data[props.row.index]?.month) - 1]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Booking Time' className='min-w-50px' />
    ),
    id: 'bookingTime',
    Cell: ({ ...props }) => {
      const bookingTime = props.data[props.row.index]?.bookingTime;
          const date = new Date(bookingTime);
    
      // Format the date to display in "Aug 17, 2024 : 10 AM" format
      const formattedDate = bookingTime
        ? `${date.toLocaleString('en-US', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()} : ${date.getHours() % 12 || 12} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
        : '';
    
      return <TitleCell userObj={formattedDate} />;
    },    
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Employee Email' className='min-w-50px' />
    ),
    id: 'employeeEmail',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.employeeEmail} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Employee Name' className='min-w-50px' />
    ),
    id: 'employeeName',
    Cell: ({ ...props }) => {
      const firstName = props.data[props.row.index]?.employeeFirstName || "";
      const lastName = props.data[props.row.index]?.employeeLastName || "";

      const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);

      return <TitleCell userObj={`${capitalize(firstName)} ${capitalize(lastName)}`} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Company Email Domain' className='min-w-50px' />
    ),
    id: 'companyEmailDomain',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.companyEmailDomain} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Company Name' className='min-w-50px' />
    ),
    id: 'companyName',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.companyName} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Subscription Package' className='min-w-50px' />
    ),
    id: 'subscriptionPackage',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.subscriptionPackage} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Branch Location' className='min-w-50px' />
    ),
    id: 'location',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.location} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Partner Email' className='min-w-50px' />
    ),
    id: 'partnerEmail',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.partnerEmail} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Partner Name' className='min-w-50px' />
    ),
    id: 'partnerName',
    Cell: ({ ...props }) => {
      const partnerName = props.data[props.row.index]?.partnerName || '';
      const formattedPartnerName = partnerName.charAt(0).toUpperCase() + partnerName.slice(1);
      return <TitleCell userObj={formattedPartnerName} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Class Date' className='min-w-50px' />
    ),
    id: 'classDate',
    Cell: ({ ...props }) => {
      const classDate = props.data[props.row.index]?.classDate;
      // Format the date to only show the date part
      const formattedDate = classDate ? new Date(classDate).toLocaleDateString() : '';
    
      return <TitleCell userObj={formattedDate} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Class Start Time' className='min-w-50px' />
    ),
    id: 'startTime',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.startTime} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Class End Time' className='min-w-50px' />
    ),
    id: 'endTime',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.endTime} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Class Name' className='min-w-50px' />
    ),
    id: 'className',
    Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.className} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Booking Status' className='min-w-50px' />
    ),
    id: 'status',
    // Cell: ({ ...props }) => <TitleCell userObj={props.data[props.row.index]?.status} />,
    Cell: ({ ...props }) => {
      const status = props.data[props.row.index]?.status;
      return <TitleCell userObj={status.toLowerCase() === "cancelled" ? "early cancelled" : status} />;
    },
  },
]

export { bookingsColumns }
