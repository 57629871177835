import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "../../../utils/shared";
import { setCloseDeleteNotificationModal } from "./notificationSlice";
const API_URL = process.env.REACT_APP_API_URL;
const GET_NOTIFICATIONS = `${API_URL}/adminWeb/notifications`;
const DELETE_NOTIFICATION = `${API_URL}/adminWeb/notification/`;


export const getNotifications = createAsyncThunk(
  "getNotifications",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { page, limit, search = '' } = values
      const { data } = await axios.get(`${GET_NOTIFICATIONS}?page=${page}&limit=${limit}&search=${search}`, {});
      return data;
    } catch (error: any) {
      const { responseMessage } = error?.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (values: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.delete(`${DELETE_NOTIFICATION}${values.id}`);
      notify(data.responseMessage, 'success');
      dispatch(getNotifications({ page: values.selectedPage || 1, limit: 10 }));
      dispatch(setCloseDeleteNotificationModal());
      return data;
    } catch (error: any) {
      const { responseMessage } = error.response?.data;
      notify(responseMessage, 'error');
      return rejectWithValue(error.message);
    }
  }
);
